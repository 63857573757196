<template>
  <section
    class="w-4/5 md:w-2/3 mx-auto bg-white text-c_lightblue shadow-xl rounded-sm"
  >
    <header-coil :title="title" :urlEN="urlEN" class="text-c_darkmagenta" />
    <div class="p-4">
      <router-link :to="{ name: 'resources' }">
        <font-awesome-icon icon="fa-solid fa-circle-chevron-left" />
        Volver a Recursos sobre COIL
      </router-link>
    </div>
    <div class="w-11/12 mx-auto">
      <h2 class="text-2xl text-c_darkmagenta">
        El arte de diseñar e implementar cursos COIL exitosos
      </h2>

      <div class="w-11/12 2xl:w-1/2 py-10 mx-auto">
        <iframe
          class="w-full mx-auto aspect-video rounded shadow-md"
          src="https://www.youtube.com/embed/YwQGUAkluM4?si=hhdODDz1RXt6TJnI"
          title="El arte de diseñar e implementar cursos COIL exitosos"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </section>
</template>

<style scoped>
article a {
  @apply text-c_darkmagenta;
}
</style>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "SomeView",
  components: {
    HeaderCoil,
  },
  data() {
    return {
      title: "Tutorial COIL",
      urlEN: "https://coil.unam.mx/en/#/resources/various",
      resources: [],
    };
  },
};
</script>
