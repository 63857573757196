<template>
  <section>
    <header-coil :title="title" :urlEN="urlEN" class="text-c_darkyellow" />
    <div class="grid gap-10 p-10 sm:grid-cols-2 md:grid-cols-3">
      <article class="shadow rounded p-4">
        <div class="flex flex-row">
          <div class="basis-1/2">
            <span class="text-orange-600">Entérate</span>
          </div>
          <div class="basis-1/2 text-right text-xs">31 de octubre de 2022</div>
        </div>
        <header>
          <a
            href="https://revista.unaminternacional.unam.mx/nota/3/geociencias-a-la-distancia-experiencias-de-un-curso-coil-durante-la-pandemia"
            target="_blank"
            class="hover:underline"
          >
            <p class="py-6 px-2">
              Geociencias a la distancia. Experiencias de un curso COIL durante
              la pandermia
            </p>
          </a>
        </header>
        <footer class="border-t border-c_darkmagenta">
          <p class="text-xs">
            Por: Elena Centeno García y Carlos Miguel Valdés González
          </p>
        </footer>
      </article>
      <article class="shadow rounded p-4">
        <div class="flex flex-row">
          <div class="basis-1/2">
            <span class="text-green-600">Experiencias</span>
          </div>
          <div class="basis-1/2 text-right text-xs">31 de octubre de 2022</div>
        </div>
        <header>
          <a
            href="https://revista.unaminternacional.unam.mx/nota/3/creacion-de-videojuegos-mi-experiencia-en-un-curso-coil"
            target="_blank"
            class="hover:underline"
          >
            <p class="py-6 px-2">
              Creación de videojuegos. Mi experiencia en un curso COIL
            </p>
          </a>
        </header>
        <footer class="border-t border-c_darkmagenta">
          <p class="text-xs">Por: Misael Vera Santana</p>
        </footer>
      </article>
      <article class="shadow rounded p-4">
        <div class="flex flex-row">
          <div class="basis-1/2">
            <span class="text-red-800">Entrevista</span>
          </div>
          <div class="basis-1/2 text-right text-xs">30 de junio de 2022</div>
        </div>
        <header>
          <a
            href="https://revista.unaminternacional.unam.mx/nota/2/matematicas-y-biologia-entre-alemania-mexico-entrevista-con-maite-wilke-berenguer-y-adrian-gonzalez-casanova"
            target="_blank"
            class="hover:underline"
          >
            <p class="py-6 px-2">
              Matemática y biología: entre Alemania-México. Entrevista con Maite
              Berenguer y Adrián González Casanova
            </p>
          </a>
        </header>
        <footer class="border-t border-c_darkmagenta">
          <p class="text-xs">Por: Carlos Maza</p>
        </footer>
      </article>
      <article class="shadow rounded p-4">
        <div class="flex flex-row">
          <div class="basis-1/2">
            <span class="text-red-800">Entrevista</span>
          </div>
          <div class="basis-1/2 text-right text-xs">28 de febrero de 2022</div>
        </div>
        <header>
          <a href="#" class="hover:underline">
            <p class="py-6 px-2">
              Estrategia COIL UNAM. Educación y prácticas artísticas y
              diseñísticas
            </p>
          </a>
        </header>
        <footer class="border-t border-c_darkmagenta">
          <p class="text-xs">
            Por: Carmen Rossette Ramírez y Rubén Cerrillo García
          </p>
        </footer>
      </article>
    </div>
  </section>
</template>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "PublicationsView",
  components: {
    HeaderCoil,
  },
  data() {
    return {
      title: "Publicaciones UNAM / COIL",
      urlEN: "https://coil.unam.mx/en/#/publications",
    };
  },
};
</script>
