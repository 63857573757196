<template>
  <header class="mb-10">
    <div class="md:flex md:flex-row text-center md:text-right p-2">
      <div class="self-center basis-1/3 md:text-left text-center">
        <router-link :to="{ name: 'home' }" class="inline-block">
          <img
            src="@/assets/images/coil-header.png"
            alt="COIL UNAM"
            width="120"
          />
        </router-link>
      </div>
      <div class="self-center basis-2/3" v-if="title">
        <h2>{{ title }}</h2>
      </div>
    </div>
    <div class="bg-gray-100 p-1 text-right">
      <a :href="urlEN" class="inline-block">
        <img src="@/assets/images/flags/us.png" width="24" class="mr-2" />
      </a>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderView",
  props: ["title", "urlEN"],
  components: {},
};
</script>
