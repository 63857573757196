<template>
  <section
    class="w-4/5 md:w-2/3 mx-auto bg-white text-c_lightblue shadow-xl rounded-sm"
  >
    <header-coil :title="title" :urlEN="urlEN" />
    <article class="flex bg-gray-100">
      <div class="col m-10 bg-white w-full p-6">
        <img
          src="@/assets/images/coilaborando/2024/junio/cover.jpg"
          class="img-fluid"
          alt=""
        />
        <div class="col text-center pt-10">
          <h1>COILaborando</h1>
          <h2 class="mb-6">Boletín Informativo</h2>
        </div>
        <section class="col w-full mt-10" id="newsletter">
          <header class="text-right">
            <p class="text-c_gold">Número 13, junio - julio de 2024</p>
          </header>
          <article>
            <h2>Editorial</h2>
            <p>
              La adopción de la metodología de intercambio virtual COIL avanza
              sostenidamente en la UNAM: durante el semestre 2024 II se llevaron
              a cabo diversas experiencias, con una destacada participación del
              Sistema de Universidad Abierta y Educación a Distancia en
              distintas disciplinas. Gracias al trabajo de quienes forman parte
              de la Comisión COIL UNAM en la Red Universitaria de Responsables
              de Internacionalización (RURI), cada vez más docentes se interesan
              por esta forma de intercambio y participan, se capacitan y
              proponen cursos para realizar en colaboración con universidades de
              otros países.
            </p>
            <p>
              Destacan entre las IES con las que mayor sinergia hemos
              encontrado, el fabuloso Centro Paula Souza, una universidad
              tecnológica pública del Estado de Sao Paulo, Brasil, con la que se
              han coordinado colaboraciones en áreas como psicología, enseñanza
              de idiomas, inteligencia artificial, marketing internacional y
              otras. Conformado por distintas sedes en esa región del gigante
              sudamericano —facultades tecnológicas o FATEC—, el Centro Paula
              Souza se va convirtiendo en un importante aliado de la UNAM en el
              proceso de crecimiento del intercambio virtual COIL.
            </p>
            <p>
              Aunque en general desde la UNAM enfrentamos dificultades para
              desenvolvernos adecuadamente en inglés, avanzamos también en ese
              sentido gracias, inicialmente, a los cursos que se ofrecen en ese
              idioma en las facultades de Ingeniería y de Contaduría y
              Administración, a las que se irán sumando los de otras entidades
              académicas. Así, estamos encontrando respuesta muy positiva en
              algunas universidades comunitarias de Estados Unidos (Community
              Colleges), a la par que iniciamos colaboraciones con otras
              instituciones de gran prestigio como la Universidad de
              Massachusetts y la de Florida en Estados Unidos, la Universidad de
              Sussex en el Reino Unido y otras.
            </p>
            <p>
              En cuanto a nuestra presencia en la plataforma
              <a
                href="https://coilconnect.org/"
                target="_blank"
                rel="noopener noreferrer"
                >COIL Connect</a
              >, que ha tenido cambios recientemente, a partir de la decisión de
              diferenciar las suscripciones institucionales (pagadas) de las
              individuales (gratuitas), nos da gusto informar que hemos
              concluido la configuración del perfil de la UNAM y que ya estamos
              listando cursos específicos que buscan par por medio de esa red.
            </p>
            <p class="my-4 lg:px-10 2xl:px-10">
              <img
                src="@/assets/images/coilaborando/2024/junio/editorial-jun24.png"
                alt="Global citizenship education"
                class="mx-auto"
              />
            </p>
            <p>
              Finalmente, para fortalecer la adopción de la perspectiva COIL en
              internacionalización sin salir de casa, desde la DGECI se avanza
              en el diseño de los elementos y rúbricas que constituirán el
              sistema de información COIL de la UNAM, que esperamos poner en
              funcionamiento en el mediano plazo, y en la organización de la
              Segunda Jornada COIL UNAM 2024 que se llevará a cabo en noviembre
              próximo en instalaciones de la UNAM en Mérida, Yucatán, y con la
              participación de especialistas internacionales.
            </p>
            <p class="my-4 lg:px-20 2xl:px-40">
              <img
                src="@/assets/images/coilaborando/2024/junio/sanatorioRendon.jpg"
                alt="Global citizenship education"
                class="mx-auto"
              />
              <span class="text-center text-xs block mt-2"
                >El ex sanatorio Rendón Peniche, sede del Centro Peninsular en
                Humanidades y Ciencias Sociales (CEPHCIS), donde se llevará a
                cabo la parte presencial de la Segunda Jornada COIL UNAM 2024.
                <em>Fuente: CEPHCIS</em></span
              >
            </p>
          </article>
          <article>
            <h2>Reseña</h2>
            <p class="mt-4 text-c_darkorange">
              "La internacionalización de la educación superior: perspectivas
              descolonizadoras"
            </p>
            <p class="text-right">por Mariana Mújica Resendiz</p>
            <p class="my-4">
              Echeverría-King, L. F., Acuña-Páez, K., & de Luque-Montaño, O.
              (2024). La internacionalización de la educación superior:
              perspectivas descolonizadoras. En L. Álvarez-Ruíz, M. Herazo Ch.,
              L. F. Echeverría K., T. Lafont C., & J. L. Barboza (Comps.),
              <em
                >Experiencias de la internacionalización en las instituciones de
                educación superior (IES) en Latinoamérica</em
              >
              (Vol. 2). Editorial CECAR.
              <a
                href="https://doi.org/10.21892/9786287515420.1"
                target="_blank"
                rel="noopener noreferrer"
                >https://doi.org/10.21892/9786287515420.1</a
              >
            </p>
            <p class="my-4 lg:px-20 2xl:px-40">
              <img
                src="@/assets/images/coilaborando/2024/junio/internacionalizacion.png"
                alt="Global citizenship education"
                class="mx-auto"
              />
            </p>
            <p>
              El artículo aborda críticamente la perspectiva tradicional sobre
              internacionalización académica, que ha sido vista como una
              competencia que amplía las desigualdades entre regiones y entre
              estudiantes. Sin embargo, la pandemia de COVID-19 ha desencadenado
              una reevaluación crítica de este paradigma, impulsando un enfoque
              más colaborativo y accesible. América Latina enfrenta desafíos
              significativos para implementar esta nueva visión de la
              internacionalización educativa: Es necesario cambiar la percepción
              de este proceso como competencia por prestigio y recursos, en
              favor de una búsqueda activa de prácticas inclusivas que eliminen
              gradualmente las formas hegemónicas de selección.
            </p>
            <p>
              En este contexto, la cooperación Sur-Sur emerge como una
              estrategia prometedora para promover este cambio de paradigma. Al
              unir fuerzas con Estados comprometidos con la equidad y la
              inclusión, se pueden superar las influencias históricas del
              dominio del Norte y abrir camino a nuevas formas de colaboración y
              aprendizaje mutuo. Para esto se necesita desmantelar las
              estructuras de poder establecidas que perpetúan la desigualdad y
              la exclusión y trabajar en la construcción de políticas y
              prácticas que garanticen el acceso equitativo a oportunidades de
              aprendizaje global.
            </p>
            <p>
              El potencial transformador de este cambio de paradigma es
              innegable. Al priorizar una visión más inclusiva de la
              internacionalización educativa, será posible pensar un futuro más
              justo y sostenible para las comunidades universitarias de América
              Latina y más allá.
            </p>
          </article>
          <article>
            <h2>Testimonio</h2>
            <p class="mt-4 text-c_gold">
              Escritura creativa entre la Facultad de Filosofía y Letras y la
              Universidad Complutense de Madrid
            </p>
            <p>
              La Dra. Margarita Palacios, docente en la Facultad de Filosofía y
              Letras, emprendió su primer curso COIL en colaboración con la
              Universidad Complutense de Madrid, abordando un área de la
              literatura que suele llenarnos de luz: la literatura infantil y
              juvenil, y además, vista desde la creación literaria misma, algo
              que no se encuentra entre las prioridades de enseñanza de los
              distintos programas de la FFyL. La escritura creativa.
            </p>
            <p>
              La Dra. Palacios relata con entusiasmo la experiencia, llevada de
              la mano de una especialista española, la Dra. Pilar Vega, que
              condujo desde Madrid a un equipo binacional en la aventura de
              crear historias para chicos y chicas.
            </p>
            <p>
              El curso de maestría, que duró el semestre completo, no sólo
              permitió que alumnos y alumnas ingresaran al mundo de la creación
              literaria, sino que “permitió imaginar la posibilidad —dice la
              Dra. Palacios— de emprender un nuevo programa de maestría en
              Escritura Creativa, similar al que se lleva en la Complutense,
              pensando en doble titulación con la UNAM, una idea que ha
              entusiasmado a las autoridades de allá. Seguiremos poniendo el
              dedo en el renglón; yo considero que esto sería una puerta de
              oportunidad para muchas personas que escriben o toman talleres, y
              además pueden participar alumnos de todas las disciplinas. Ojalá
              podamos lograrlo”.
            </p>
            <p>
              A la pregunta sobre su percepción de la experiencia, la Dra.
              Palacios responde: "La interacción entre los alumnos españoles y
              mexicanos propició un valioso intercambio de preguntas sobre el
              léxico, las narraciones y las costumbres de ambas culturas, que
              mantuvieron el interés y la constancia de los participantes.
              Lamentablemente tuvimos algunos (pocos) contratiempos de conexión
              y nos afectó la alteración del horario entre México y España. En
              México ya no se acoplan los horarios de verano y tuvimos que
              ajustar la hora de clase en México con problemas para nuestros
              alumnos mexicanos. Salvamos la situación. Para mí ha sido una
              experiencia académica bien enriquecedora. Sigo juntando textos
              para una posible publicación".
            </p>
            <p class="my-4 lg:px-20 2xl:px-40 text-center">
              <img
                src="@/assets/images/coilaborando/2024/junio/cienciasComplutense.png"
                alt="Global citizenship education"
                class="mx-auto"
              />
              <span
                >La Facultad de Ciencias de la Información de la Universidad
                Complutense de Madrid<br />(fuente:
                <a
                  href="https://ccinformacion.ucm.es"
                  target="_blank"
                  rel="noopener noreferrer"
                  >https://ccinformacion.ucm.es</a
                >)</span
              >
            </p>
            <h5>Datos del curso COIL</h5>
            <p>Colaboración:</p>
            <ul>
              <li>
                UNAM: Programa de posgrado (maestría) de Filosofía, a través de
                la Cátedra Extraordinaria Maestros del Exilio Español, adaptada
                para el desarrollo de la colaboración
              </li>
              <li>
                UCM: Máster Universitario en Escritura Creativa, Facultad de
                Ciencias de la Información, Filología española
              </li>
            </ul>
            <p>Título del COIL:</p>
            <ul>
              <li>
                Curso taller: Literatura infantil: escritura creativa. Escribir
                y crear para el mundo infantil
              </li>
            </ul>
            <p>Duración y características:</p>
            <ul>
              <li>25 de enero al 23 de mayo de 2024</li>
              <li>
                32 horas (sesiones sincrónicas los jueves de 9 a 11, hora de
                CDMX)
              </li>
              <li>
                7 alumnos en España, todos asistiendo presencialmente al aula
                desde la que se enlazó con México
              </li>
              <li>
                17 alumnos en México; 5 asistiendo en línea desde provincia, 12
                en aula
              </li>
            </ul>
          </article>
          <article>
            <h2>Noticias</h2>
            <p class="my-4 text-c_darkorange">Septiembre</p>
            <p class="my-4 lg:px-20 2xl:px-40 text-center">
              <img
                src="@/assets/images/coilaborando/2024/junio/coil4Latinoamerica.png"
                alt="Global citizenship education"
                class="mx-auto"
              />
              <span>4 Congreso de la Red Latinoamericana COIL</span>
            </p>
            <p>
              La Red Latinoamericana COIL (LATAM COIL) organiza su cuarto
              congreso virtual, del 4 al 6 de septiembre de 2024, con el tema
              “COIL y la diversidad, la equidad y la inclusión”.
            </p>
            <p>
              La ventana para enviar propuestas a las diversas mesas ya ha
              cerrado, pero el registro para participar en el evento y
              aprovechar la capacitación y la oportunidad de conocer IES y
              personas con las que establecer pares colaborativos estará abierta
              hasta el 2 de septiembre de 2024.
            </p>
            <p class="text-right">
              <a
                href="https://www.udem.edu.mx/es/institucional/congreso-red-latam-coil"
                target="_blank"
                rel="noopener noreferrer"
                >Más información</a
              >
            </p>
            <p class="my-4 text-c_darkorange">Octubre</p>
            <p class="my-4 lg:px-20 2xl:px-40 text-center">
              <img
                src="@/assets/images/coilaborando/2024/junio/conferencia22Connect.jpg"
                alt="Global citizenship education"
                class="mx-auto"
              />
              <span
                >22ª Conferencia de Educación Superior de América del Norte del
                Consorcio para la Colaboración en la Educación Superior de
                América del Norte (CONAHEC)</span
              >
            </p>
            <p>
              Este evento se llevará a cabo del 23 al 25 de octubre en Ensenada,
              Baja California.
            </p>
            <p>
              El registro para asistir como audiencia tiene un descuento
              importante hasta el 31 de agosto y tarifas normales en septiembre.
            </p>
            <p class="text-right">
              <a
                href="https://www.conahec.org"
                target="_blank"
                rel="noopener noreferrer"
                >Más información</a
              >
            </p>
          </article>
        </section>
        <p class="mt-6 text-center">
          <router-link
            :to="{ name: 'previos' }"
            class="bg-c_darkmagenta hover:bg-c_lightmagenta p-2 rounded text-gray-50"
          >
            ver otros números
          </router-link>
        </p>
      </div>
    </article>
  </section>
</template>

<style scoped>
a.inline-block.bg-c_darkyellow {
  @apply my-4 rounded-sm;
  text-decoration: none !important;
}
ul li {
  @apply list-disc text-xs ml-6 my-2;
}
</style>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "JunioView",
  components: {
    HeaderCoil,
  },
  data() {
    return {
      title: "",
      urlEN: "https://coil.unam.mx/#/coilaborando/previos/2024/junio",
    };
  },
};
</script>
