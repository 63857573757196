<template>
  <div class="jornada__callout-carousel">
    <carousel
      autoplay="true"
      perPage="1"
      speed="2000"
      autoplayTimeout="10000"
      paginationColor="#eb775e"
      paginationActiveColor="#e5e7eb"
    >
      <slide class="jornada__callout-carousel-item">
        <img
          src="@/assets/images/jornada2th/enes-01.jpg"
          alt="2a Jornada COIL"
        />
        <p class="jornada__callout-carousel-label">ENES Mérida</p>
      </slide>
      <slide class="jornada__callout-carousel-item">
        <img
          src="@/assets/images/jornada2th/merida-01.jpg"
          alt="2a Jornada COIL"
        />
        <p class="jornada__callout-carousel-label">
          Mérida Yucatán - Centro Histórico
        </p>
      </slide>
      <slide class="jornada__callout-carousel-item">
        <img
          src="@/assets/images/jornada2th/cephcis-01.jpg"
          alt="2a Jornada COIL"
        />
        <p class="jornada__callout-carousel-label">CEPHCIS</p>
      </slide>
      <slide class="jornada__callout-carousel-item">
        <img
          src="@/assets/images/jornada2th/merida-02.jpg"
          alt="2a Jornada COIL"
        />
        <p class="jornada__callout-carousel-label">
          Mérida Yucatán - Palacio del Ayuntamiento
        </p>
      </slide>
      <slide class="jornada__callout-carousel-item">
        <img
          src="@/assets/images/jornada2th/enes-02.jpg"
          alt="2a Jornada COIL"
        />
        <p class="jornada__callout-carousel-label">ENES Mérida</p>
      </slide>
      <slide class="jornada__callout-carousel-item">
        <img
          src="@/assets/images/jornada2th/cephcis-02.jpg"
          alt="2a Jornada COIL"
        />
        <p class="jornada__callout-carousel-label">CEPHCIS - Rendón Peniche</p>
      </slide>
      <slide class="jornada__callout-carousel-item">
        <img
          src="@/assets/images/jornada2th/merida-03.jpg"
          alt="2a Jornada COIL"
        />
        <p class="jornada__callout-carousel-label">
          Mérida Yucatán - Palacio de Gobierno
        </p>
      </slide>
      <slide class="jornada__callout-carousel-item">
        <img
          src="@/assets/images/jornada2th/enes-03.jpg"
          alt="2a Jornada COIL"
        />
        <p class="jornada__callout-carousel-label">ENES Mérida</p>
      </slide>
      <slide class="jornada__callout-carousel-item">
        <img
          src="@/assets/images/jornada2th/cephcis-03.jpg"
          alt="2a Jornada COIL"
        />
        <p class="jornada__callout-carousel-label">CEPHCIS - Dragones</p>
      </slide>
      <slide class="jornada__callout-carousel-item">
        <img
          src="@/assets/images/jornada2th/merida-04.jpg"
          alt="2a Jornada COIL"
        />
        <p class="jornada__callout-carousel-label">
          Mérida Yucatán - Palacio Cantón
        </p>
      </slide>
      <slide class="jornada__callout-carousel-item">
        <img
          src="@/assets/images/jornada2th/enes-04.jpg"
          alt="2a Jornada COIL"
        />
        <p class="jornada__callout-carousel-label">ENES Mérida</p>
      </slide>
      <slide class="jornada__callout-carousel-item">
        <img
          src="@/assets/images/jornada2th/cephcis-04.jpg"
          alt="2a Jornada COIL"
        />
        <p class="jornada__callout-carousel-label">CEPHCIS - Casa Lol-Be</p>
      </slide>
    </carousel>
  </div>
</template>

<style scoped>
.jornada__callout-carousel-item {
  position: relative;
}
.jornada__callout-carousel-label {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  padding: 0.5rem;
}
</style>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "CarouselMerida",
  components: {
    Carousel,
    Slide,
  },
};
</script>
