<template>
  <section>
    <header-coil :title="title" :urlEN="urlEN" class="text-c_gold" />
    <article>
      <div class="w-full p-2">
        <h2>Reseñas</h2>
        <div class="mt-4 bg-gray-100 p-2 md:grid md:grid-cols-2 md:gap-2">
          <div>
            <h3 class="text-md text-c_darkyellow">
              Las universidades, protagonistas de una realidad interconectada
            </h3>
          </div>
          <div class="md:row-span-2 grid content-center">
            <img
              src="https://www.gaceta.unam.mx/wp-content/uploads/2023/10/231026-Com4-des-f1-Jornada-COIL-UNAM.jpg"
              alt=""
            />
          </div>
          <div>
            <p class="text-xs">
              Con el propósito de aprovechar una plataforma de educación
              novedosa llamada Collaborative Online International Learning
              (COIL), similar a una red social que vincula a profesores y
              estudiantes de todo el mundo, se realizó la Primera Jornada
              COIL-UNAM 2023.
            </p>
            <p>
              <a
                href="https://www.gaceta.unam.mx/realizan-primera-jornada-de-trabajo-coil-unam/"
                target="_blank"
                rel="noopener noreferrer"
                class="inline-block bg-yellow-700 text-white py-1 px-2 rounded mt-2 mr-2 text-xs"
                >Ver nota completa</a
              >
            </p>
          </div>
        </div>
        <div class="mt-4 bg-gray-100 p-2 md:grid md:grid-cols-2 md:gap-2">
          <div class="md:row-span-2 grid content-center">
            <img
              src="https://monitorfinanciero.com.mx/wp-content/uploads/2023/10/Primera-Jornada-COIL-3.jpg"
              alt="FES Acatlán, presente en la Primera Jornada COIL UNAM 2023"
            />
          </div>
          <div>
            <h3 class="text-md text-c_darkyellow">
              FES Acatlán, presente en la Primera Jornada COIL UNAM 2023
            </h3>
          </div>
          <div>
            <p class="text-xs">
              La Facultad de Estudios Superiores Acatlán participó en la Primera
              Jornada COIL UNAM 2023, celebrada en la Coordinación de
              Universidad Abierta Innovación Educativa y Educación a Distancia
              (CUAIEED) en Ciudad Universitaria, evento en la que la maestra
              Adriana Roque del Ángel, jefa del Departamento de Servicio Social,
              Desarrollo Profesional e Inserción Laboral compartió experiencias
              internacionales, específicamente con el intercambio académico con
              el Instituto Tecnológico Universitario Cordillera (ITSCO), de
              Ecuador.
            </p>
            <p>
              <a
                href="https://monitorfinanciero.com.mx/conurbadas/fes-acatlan-presente-en-la-primera-jornada-coil-unam-2023/"
                target="_blank"
                rel="noopener noreferrer"
                class="inline-block bg-yellow-700 text-white py-1 px-2 rounded mt-2 mr-2 text-xs"
                >Ver nota completa</a
              >
            </p>
          </div>
        </div>
      </div>
      <div class="program w-full p-6">
        <h2 class="mb-8">Programa: 19 de octubre 2023</h2>
        <div class="grid gap-6 md:grid-cols-2 xl:grid-cols-3 mb-8">
          <iframe
            class="w-full aspect-video"
            src="https://www.youtube.com/embed/CayeYWxNxOo"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <iframe
            class="md:col-start-2 xl:col-start-3 w-full aspect-video"
            src="https://www.youtube.com/embed/7xE7-GqACp8"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <div class="panel grid gap-6 md:grid-cols-2 xl:grid-cols-3 mb-10">
          <article>
            <header>Inauguración</header>
            <div>
              <ul>
                <li>
                  Dr. Melchor Sánchez Mendiola<span
                    >Coordinador de Universidad Abierta Innovación Educativa y
                    Educación a Distancia (CUAIEED)</span
                  >
                </li>
                <li>
                  Mtro. Gerardo Reza<span
                    >Director de Cooperación e Internacionalización
                    (DGECI)</span
                  >
                </li>
                <li>Dr. Jon Rubin<span>COIL Consulting</span></li>
              </ul>
            </div>
            <footer>
              <p class="text-xs text-right">
                Presenta: Mtra. Dolores González-Casanova
              </p>
            </footer>
          </article>
          <article>
            <header>Conferencia Magistral</header>
            <div>
              <ul>
                <li>Dr. Jon Rubin<span>COIL Consulting</span></li>
              </ul>
            </div>
            <footer>
              <p class="text-xs text-right">
                Presenta: Lic. Carlos Maza<br /><em>
                  (en inglés con traducción simultánea al español)</em
                >
              </p>
            </footer>
          </article>
          <article>
            <header>
              Conversatorio: Experiencias exitosas y buenas prácticas
            </header>
            <div>
              <ul>
                <li>
                  Dr. Carlos López Beltrán<span
                    >Instituto de Investigaciones Filosóficas</span
                  >
                </li>
                <li>Dra. Berenice Ovalle<span>Facultad de Química</span></li>
                <li>
                  Mtra. Adriana Roque<span
                    >Facultad de Estudios Superiores Acatlán</span
                  >
                </li>
                <li>
                  Mtra. Carmen Rosette Ramírez<span
                    >Facultad de Artes y Diseño</span
                  >
                </li>
                <li>
                  Lic. Pedro Alejandro Alarcón<span
                    >Facultad de Contaduría y Administración</span
                  >
                </li>
              </ul>
            </div>
            <footer>
              <p class="text-xs text-right">Coordina: Dra. Guadalupe Vadillo</p>
            </footer>
          </article>
        </div>
        <div class="grid gap-6 md:grid-cols-2 xl:grid-cols-3 mb-10">
          <article>
            <header>Taller A: Una bienvenida al mundo COIL</header>
            <div>
              <ul>
                <li>Lic. Carlos Maza Pesqueira</li>
              </ul>
            </div>
          </article>
          <article>
            <header>
              Taller B: Experiencias COIL en UNAM, buenas prácticas en gestión
            </header>
            <div>
              <ul>
                <li>Mtra. Dolores González-Casanova</li>
              </ul>
            </div>
          </article>
          <article>
            <header>Taller C: Muchas soluciones para los retos</header>
            <div>
              <ul>
                <li>Dra. Guadalupe Vadillo</li>
              </ul>
            </div>
          </article>
        </div>
        <div class="grid gap-10">
          <article>
            <header>Taller dirigido a la Comisión COIL y a la RURI</header>
            <div>
              <ul>
                <li>Dr. Jon Rubin<span>COIL Consulting</span></li>
              </ul>
            </div>
          </article>
        </div>
      </div>
    </article>
  </section>
</template>

<style scoped>
.program .grid > article {
  @apply p-4 pb-8 relative;
}
.program .grid.panel > article {
  @apply pb-16;
}
.program .grid:nth-child(even) > article {
  background-color: #f5f5f5;
}
.program .grid:nth-child(odd) > article {
  @apply border border-gray-200;
}
.program .grid > article > footer {
  position: absolute;
  bottom: 0;
  left: 0;
  @apply w-full pr-4 mb-4;
}
.program header {
  @apply mb-2 text-xl;
}
.program ul li {
  @apply text-sm mb-1;
}
.program ul li > span {
  @apply text-xs text-gray-700 block ml-2;
}
</style>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "JornadaCoilView",
  components: {
    HeaderCoil,
  },
  data() {
    return {
      title: "Primera Jornada COIL UNAM 2023",
      urlEN: "https://coil.unam.mx/#/jornada-coil",
    };
  },
};
</script>
