<template>
  <section
    class="w-4/5 md:w-2/3 mx-auto bg-white text-c_lightblue shadow-xl rounded-sm"
  >
    <header-coil :title="title" :urlEN="urlEN" />
    <article class="flex bg-gray-100">
      <div class="col m-10 bg-white w-full p-6">
        <img
          src="@/assets/images/coilaborando/2024/marzo/cover.jpg"
          class="img-fluid"
          alt=""
        />
        <div class="col text-center pt-10">
          <h1>COILaborando</h1>
          <h2 class="mb-6">Boletín Informativo</h2>
        </div>
        <section class="col w-full mt-10" id="newsletter">
          <header class="text-right">
            <p class="text-c_gold">Número 10, marzo de 2024</p>
          </header>
          <article>
            <h2>Editorial</h2>
            <p>
              <img
                src="@/assets/images/coilaborando/editorial-marzo.jpg"
                alt="El arte de diseñar e implementar cursos COIL exitosos"
                class="w-11/12 md:w-2/3 lg:w-1/2 mx-auto block"
              />
            </p>
            <p class="mt-10 my-4 text-c_darkyellow">
              <em>El arte de diseñar e implementar cursos COIL exitosos</em>
            </p>
            <p>
              Ocho integrantes de la Comisión COIL participaron en la producción
              del tutorial en video “El arte de diseñar e implementar cursos
              COIL exitosos”. La CUAED proporcionó la infraestructura de
              grabación, producción y postproducción —y un divertido refrigerio
              con gansitos y pingüinos—; la Comisión COIL puso el contenido
              elaborado colaborativamente, y el día 1° de marzo nos dimos cita
              en los estudios de la CUAED para grabar el tutorial. Pronto estará
              disponible para toda la comunidad universitaria, acompañado de un
              útil manual para la implementación de cursos COIL.
            </p>
            <p class="mt-10 my-4 text-c_darkyellow">
              <em>COIL Leadership Institute</em>
            </p>
            <div class="text-right mb-6">
              <p class="text-c_darkmagenta italic">
                COIL is not free, but you may already have most of the resources
                you need to do it.
              </p>
              <p>- Jon Rubin -</p>
            </div>
            <p class="my-4">
              <img
                src="@/assets/images/coilaborando/fiu-coil.png"
                alt="COIL Leadership Institute"
                class="mx-auto max-w-lg block"
              />
            </p>
            <p>
              Un mes atrás, en el número 9 de COILaborando, informábamos que un
              equipo de la Comisión COIL de la UNAM, integrado por Dolores
              González-Casanova, Erik Granados, Carlos Maza y Guadalupe Vadillo,
              asistiría al diplomado de la Universidad Internacional de Florida
              (FIU) y COIL Consulting, COIL Leadership Institute. Pues bien: el
              día 9 de abril será el último día de este encuentro que ha
              resultado sumamente enriquecedor para el equipo de la UNAM y, por
              ende, para el proceso de adopción de las metodologías COIL a lo
              largo y ancho de nuestra universidad.
            </p>
            <p>
              No solamente hemos aprendido sobre temas centrales para la
              coordinación de COIL en el futuro, sino que, gracias a un clima de
              empatía y colaboración propiciado por los organizadores (Stephanie
              Doscher y su equipo por parte de la FIU, y Jon Rubin por parte de
              COIL Consulting), hemos establecido también relaciones con
              instituciones muy interesantes con las que pronto tendremos
              proyectos COIL.
            </p>
            <p class="mt-10 my-4 text-c_darkyellow">
              <em>Front Range Community College</em>
            </p>
            <p>
              Destacan entre las IES que hemos contactado gracias al Institute,
              la universidad comunitaria (Community College) Front Range, en
              Colorado, Estados Unidos, con la que ya se coordinan dos COIL en
              colaboración con la Facultad de Artes y Diseño de la UNAM y se
              planean cursos en al menos tres campos disciplinarios más
              (administración, biología, ciencias ambientales). El modelo de las
              universidades comunitarias en los Estados Unidos es interesante
              para nosotros pues es desde estas instituciones desde donde se
              alcanza sectores de la población menos favorecidos, bajo un
              esquema centrado en el acceso. No se trata de educación gratuita
              propiamente dicha —es algo que en Estados Unidos resulta más
              complicado—, pero es educación con responsabilidad social, un tema
              central de las universidades públicas mexicanas y
              latinoamericanas.
            </p>
            <p class="mt-10 my-4 text-c_darkyellow">
              <em>Centro Paula Souza</em>
            </p>
            <div class="text-right mb-6">
              <p class="text-c_darkmagenta italic">
                ¡Hubo música en la coordinación México-Brasil!
              </p>
            </div>
            <p>
              Otra institución con la que hemos establecido lazos que llevarán a
              interesantes cursos COIL es el sistema educativo de nivel medio
              superior y técnico conocido como Centro Paula Souza, del gobierno
              del Estado de Sao Paulo, Brasil. El CPS está conformado por varias
              sedes descentralizadas conocidas como Facultades Técnológicas
              (FATEC); lo que lo hace similar a la UNAM en cuanto a su tamaño y
              su organización desconcentrada. El CPS lleva cerca de una década
              organizando exitosos cursos de intercambio virtual y ha
              manifestado un enorme interés por colaborar con la UNAM, con lo
              que ambas instituciones se verán beneficiadas, tendremos a nuestro
              alcance el modus operandi para COIL de una de las instituciones
              más desarrolladas en ello en nuestro continente.
            </p>
          </article>
          <article>
            <h2>Eventos y talleres</h2>
            <h3>Introducción a COIL UNAM (DGECI)</h3>
            <p>
              La DGECI sigue avanzando con los talleres de introducción a COIL
              ofrecidos a autoridades y docentes de diversas entidades
              académicas de la UNAM. Tenemos el gusto de informar que a partir
              de abril se incorpora como tallerista en estas sesiones el
              responsable de Internacionalización de la ENES Mérida, Erik
              Granados.
            </p>
            <p>
              Durante el mes de abril se impartirá el taller introductorio en la
              Facultad de Contaduría y Administración (10 de abril, 18h, formato
              híbrido), en la Facultad de Ingeniería (17 de abril, dos sesiones:
              11h y 17h, virtual) y en la ENES Morelia (fecha por definir). Si
              te interesa asistir a alguna de estas presentaciones, ponte en
              contacto con: Mitzi Gómez, FCA,
              <a href="mailto:migomez@fca.unam.mx">migomez@fca.unam.mx</a> (para
              la sesión del 10 de abril); Gabriela Alfaro, FI,
              <a href="mailto:gaby@ingenieria.unam.edu"
                >gaby@ingenieria.unam.edu</a
              >
              (para las sesiones del 17 de abril), o con Carlos Maza, DGECI,
              <a href="mailto:cmaza@global.unam.mx">cmaza@global.unam.mx</a>
              para la sesión que se impartirá a la ENES Morelia o bien para
              programar una sesión en tu entidad académica.
            </p>
            <p class="my-4">
              <a
                href="https://forms.office.com/pages/responsepage.aspx?id=ECkoX28XnUWrfbdEqm78ByEWajvOW8VKjElYY0YU0UBUQzNBWUcwSVJVODQzM1FJNEs3U09INVUzQi4u&origin=QRCode"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="@/assets/images/coilaborando/taller-intro-coil.jpg"
                  alt="Introducción a COIL UNAM (DGECI)"
                  class="mx-auto max-w-lg block"
                />
              </a>
            </p>
            <h3>Congreso IVEC</h3>
            <p class="my-4">
              <img
                src="@/assets/images/coilaborando/congreso-ivec.png"
                alt="COIL Leadership Institute"
                class="mx-auto max-w-lg block"
              />
            </p>
            <p>
              IVEC, una de las instituciones de soporte global para la adopción
              de metodologías de intercambio virtual, que trabaja de forma muy
              cercana a FIU y a COIL Consulting, organiza anualmente un congreso
              para discutir estos temas. En 2024 el encuentro será solamente
              virtual, se llevará a cabo del 21 al 24 de octubre y tendrá
              diversos formatos de participación. Un equipo de la Comisión COIL
              de la UNAM se encuentra preparando una presentación "flash"
              (presentaciones breves mediante las que se comparte un tema
              específico a una audiencia). El registro al congreso IVEC se
              abrirá el 15 de mayo, COILaborando informará oportunamente los
              detalles.
            </p>
            <p>
              Más información sobre el encuentro de IVEC en:
              <a
                href="https://iveconference.org/"
                target="_blank"
                rel="noopener noreferrer"
                >iveconference.org</a
              >
            </p>
          </article>
        </section>
        <p class="mt-6 text-center">
          <router-link
            :to="{ name: 'previos' }"
            class="bg-c_darkmagenta hover:bg-c_lightmagenta p-2 rounded text-gray-50"
          >
            ver otros números
          </router-link>
        </p>
      </div>
    </article>
  </section>
</template>

<style scoped>
a.inline-block.bg-c_darkyellow {
  @apply my-4 rounded-sm;
  text-decoration: none !important;
}
ul li {
  @apply list-disc text-xs ml-6 my-2;
}
</style>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "MarzoView",
  components: {
    HeaderCoil,
  },
  data() {
    return {
      title: "",
      urlEN: "https://coil.unam.mx/#/coilaborando/previos/marzo",
    };
  },
};
</script>
