<template>
  <section>
    <header-coil :title="title" :urlEN="urlEN" class="text-c_darkmagenta" />
    <div class="md:flex md:flex-row">
      <div class="md:basis-1/2 p-6">
        <a href="https://forms.gle/ALywhG9No1cBY93G7" target="_blank">
          <img src="../assets/images/mexico.jpg" alt="México" />
          <p class="text-center bg-c_darkmagenta text-gray-50">
            Soy docente UNAM
          </p>
        </a>
      </div>
      <div class="md:basis-1/2 p-6">
        <a href="https://forms.gle/qY8LcTaVyikUL4L77" target="_blank">
          <img src="../assets/images/mundo.jpg" alt="Resto del mundo" />
          <p class="text-center bg-c_darkorange text-gray-50">
            Soy docente fuera de México
          </p>
        </a>
      </div>
    </div>
  </section>
</template>

<style scoped>
div.p-6:first-child > a:hover > p {
  @apply bg-c_lightmagenta;
}
div.p-6:last-child > a:hover > p {
  @apply bg-c_lightorange;
}
</style>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "InterestedView",
  components: {
    HeaderCoil,
  },
  data() {
    return {
      title: "¿Te interesa diseñar un COIL?",
      urlEN: "https://coil.unam.mx/en/#/interested",
    };
  },
};
</script>
