<template>
  <section
    class="w-4/5 md:w-2/3 mx-auto bg-white text-c_lightblue shadow-xl rounded-sm"
  >
    <header-coil :title="title" :urlEN="urlEN" />
    <article class="flex bg-gray-100">
      <div class="col m-10 bg-white w-full p-6">
        <img
          src="@/assets/images/coilaborando/2023/julio.jpg"
          class="img-fluid"
          alt=""
        />
        <div class="col text-center pt-10">
          <h1>COILaborando</h1>
          <h2 class="mb-6">Boletín Informativo</h2>
        </div>
        <section class="col w-full mt-10" id="newsletter">
          <header class="text-right">
            <p class="text-c_gold">Número 2, julio de 2023</p>
          </header>
          <article>
            <h2>Avances en el desarrollo de COIL en la UNAM</h2>
            <p>
              Desde antes de que se emprendiera la labora de impulso a la
              metodología COIL en la UNAM, nuestros docentes ya lo realizaban.
              Es casi natural: impartes una materia que sabes que una conocida
              en otro país también aborda, ¿por qué no escribirle para abordarla
              juntos? Incluso mezclando ambos grupos. Y los resultados no
              previstos, aquellos que desbordan los objetivos planteados de
              aprendizaje, empiezan a colorearlo todo: competencias
              multiculturales a través de la pantalla, idiomas que se vuelven
              inteligibles cuando compartimos nuestras dudas, actividades
              divertidas que, además, generan aprendizajes colaborativos, y
              hasta publicaciones, carteles y videojuegos.
            </p>
            <p>
              Lo anterior significa que las capacidades para desarrollar
              intercambios virtuales de gran intensidad, capaces de incorporarse
              a la estrategia general de internacionalización de nuestra
              universidad (y de nuestro país) ya se encuentran a nuestro
              alcance, y nos toca ahora consolidarlas. El esquema COIL, por su
              flexibilidad, su adaptabilidad, y a la vez, su enorme capacidad de
              generar aprendizajes dinámicos, está aquí para fortalecer mucho de
              lo que ya hacemos y sabemos, y para ordenar los procesos que lo
              permiten.
            </p>
            <p>
              El programa COIL UNAM avanza gracias al impulso de los y las
              responsables de internacionalización que integran la Comisión COIL
              y que articulan esfuerzos desde muchas entidades académicas para
              hacer crecer esta forma de intercambio académico fundamentada en
              lo virtual.
            </p>
            <p>
              Nuestra aproximación crece; poco a poco se van generando más
              experiencias COIL desde la UNAM, en coordinación con IES de
              Norteamérica, América Latina y Europa, y cada vez más de nuestros
              docentes se interesan por emprender otras. Es por esto que la CRAI
              y la DGECI, en coordinación con la CUAIEED y la Comisión COIL de
              la RURI organizan para el presente semestre un encuentro destinado
              a difundir la estrategia y a generar competencias al respecto en
              nuestra comunidad académica. No te pierdas el próximo número de
              <strong>COIL<em>aborando</em></strong> que incluirá una invitación
              a participar en la Primera Jornada COIL UNAM 2023, a realizarse en
              octubre.
            </p>
          </article>
          <article>
            <h2>Reseña</h2>
            <p class="cite">
              Jos Beelen y Stephanie Doscher, “Situating COIL virtual Exchange
              within concepts of internationalizatión”, 2022. Rubin, J., y Guth,
              S.,
              <em>The Guide to COIL Virtual Exchange</em>. Nueva York: Stylus,
              pp. 32-54
            </p>
            <p class="text-right">Por Dagny L. Valadez</p>
            <p class="my-4">
              <img
                src="@/assets/images/coilaborando/the_guide_to_coil.jpg"
                alt="The Guide to COIL Virtual Exchange"
                class="mx-auto"
              />
            </p>
            <p>
              Este capítulo es ideal para todos aquellos que requerimos de un
              marco teórico más amplio y, sobre todo, de la clarificación de
              conceptos que enmarquen la estrategia COIL. Parecería que la
              lectura de conceptos puede ser tediosa pero los autores ofrecen un
              amplio abanico de posturas y propuestas que hacen que la lectura
              sea ágil y agradable.
            </p>
            <p>
              Algunas de las ideas que se presentan a lo largo del texto pueden
              vincularse con las reflexiones que hace Jaques Delors en su
              <span class="underline"
                >Informe a la UNESCO de la Comisión Internacional sobre la
                educación del siglo XXI. La educación encierra un tesoro
                (1997)</span
              >, en el que plantea que ya no sólo los conocimientos duros de los
              programas académicos serán suficientes para el desarrollo de los
              profesionales del mañana, sino que es necesario que desarrollen
              habilidades blandas que les permitan desenvolverse en ambientes
              interculturales, multiculturales y sustentables, es decir, crear
              ciudadanos globales. Para lograr lo anterior, la
              internacionalización es una herramienta indispensable, ya que es
              el medio para propiciar la interrelación entre pares de diversas
              culturas.
            </p>
            <p>
              La definición de internacionalización varía dependiendo de cada
              institución y sus intereses particulares, no obstante, el fin
              siempre es proveer a los alumnos de experiencias que les permitan
              trabajar en grupos diversos para conocer culturas, idiomas y
              puntos de vista diferentes. Es aquí en donde vemos la
              Internacionalización como un gran objetivo y la
              Internacionalización en Casa como un paso para llegar a la meta.
            </p>
            <p>
              Una de las herramientas para la internacionalización en casa es el
              intercambio COIL. En el texto, los autores desarrollan tanto las
              ventajas como las desventajas de la estrategia y dan un amplio
              panorama de la multiplicidad de opiniones al respecto: desde los
              beneficios para reducir la huella de carbón hasta las desventajas
              de fomentar la hegemonía de la lengua inglesa como
              <em>Lingua Franca</em>.
            </p>
            <p>
              La lectura de este capítulo puede resultar en varias vertientes:
              en una reflexión sobre los objetivos esperados de la estrategia
              COIL en nuestra universidad; en un análisis de los requisitos para
              poder concretar con éxito una estrategia de Internacionalización
              en Casa; y en la multiplicidad de opciones que tenemos para
              planear la estrategia en las diversas Entidades Académicas de la
              UNAM.
            </p>
          </article>
          <article>
            <h2>Eventos</h2>
            <h3>La UNAM en el 3er. congreso de la red LATAM COIL</h3>
            <p class="my-4">
              <img
                src="@/assets/images/coilaborando/tercer_congreso.jpg"
                alt="La UNAM en el 3er. congreso de la red LATAM COIL"
                class="mx-auto"
              />
            </p>
            <p>
              Como anunciamos en nuestro primer número, la semana del 12 al 16
              de junio pasado se llevó a cabo, de manera virtual, el 3er
              Congreso de LATAM COIL, la red que articula a universidades de
              América Latina (y ahora de otras regiones) en torno de la
              promoción y adopción de las metodologías COIL. Organizada por la
              Universidad Veracruzana (en la que actualmente recae la secretaría
              general de esta red) y la Universidad de Monterrey, junto con la
              Universidad Estadual de São Paulo, Brasil y la Universidad
              Católica de Colombia —las cuatro IES creadoras de la red—, la
              tercera edición del congreso versó sobre las estrategias que las
              distintas IES adoptan para mantener el ritmo de adopción de COIL
              una vez que el fin de la pandemia nos devolvió la posibilidad de
              seguir realizando nuestras actividades de manera presencial.
            </p>
            <p>
              En respuesta a la pregunta “COIL después de la pandemia: ¿hacia
              dónde vamos?”, los cinco días de actividades, con asistencia de
              más de 120 personas y participación de más de 40 ponentes en
              diversos formatos de exposición —desde la conferencia magistral
              hasta las entretenidas "<em>ingniting sessions</em>"— dejaron ver
              una amplia diversidad de estrategias, consejos, propuestas y
              adaptaciones de las metodologías COIL a distintos entornos,
              incluyendo la polémica sobre si COIL debe ser siempre
              internacional o si la “I” significa, además de internacional,
              también intercultural e interdisciplinario. De cualquier modo, es
              la internacionalización por la vía del intercambio virtual el
              objetivo que destaca para todas las IES que asistieron al evento.
            </p>
            <p>
              En representación de la UNAM, la DGECI ha asistido a las tres
              ediciones de este congreso; sin embargo, por primera vez lo hizo
              llevando una ponencia que describía el trabajo de la Comisión COIL
              en la RURI, el lanzamiento del programa COIL UNAM y el boletín
              <strong>COIL<em>aborando</em></strong> en el que ahora mismo lees
              estas palabras. Queda clara la importancia que la UNAM tiene para
              las IES que se enlazan vía intercambio virtual en la cantidad de
              propuestas que recibimos y en el amable diálogo que la red LATAM
              COIL ha establecido con la UNAM.
            </p>
            <h3>IVEC 2023, en octubre, en São Paulo, Brasil</h3>
            <p class="my-4">
              <img
                src="@/assets/images/coilaborando/ivec_2023.jpg"
                alt="IVEC 2023"
                class="mx-auto"
              />
            </p>
            <p>
              Del 30 de octubre al 1° de noviembre de 2023, la vibrante ciudad
              brasileña de São Paulo será sede de la quinta edición de IVEC 2023
              (siglas en inglés de Congreso Internacional de Intercambio
              Virtual), un importante evento que aglutina experiencias de
              intercambio virtual internacional en las que se incluye COIL,
              Telecollaboration (la perspectiva europea), el aprendizaje en red
              global y otras formas de intercambio virtual. En esta ocasión
              convocan FAUBAI (Asociación Brasileña para la Educación
              Internacional) y la UNESP (Universidade Estadual Paulista).
            </p>
            <p>
              El congreso IVEC es una nueva encarnación, más globalizada y
              extensa, de lo que se inició años atrás como Congreso COIL de la
              Universidad del Estado de Nueva York, realizado entre 2007 y 2018,
              y de los congresos bianuales de UniCollaboration, el consorcio de
              telecolaboración europeo, que se iniciaron en 2014. Bajo el nuevo
              avatar de IVEC, el alcance de estos encuentros es mucho más
              integral y abarcador. Habrá sesiones magistrales, grupos de
              diálogo, encuentros de establecimiento de red
              (<em>networking</em>), y exposiciones de nuevos actores en la
              escena del intercambio virtual. El registro temprano (de menor
              costo, conocido en inglés como <em>early bird</em>) para
              participar en este encuentro presencial/virtual estará abierto
              hasta el 15 de agosto, y el registro normal, hasta el 1° de
              octubre. Los costos para acceder por vía virtual son menores que
              los presenciales, y el costo para estudiantes es muy accesible.
              Toda la información se encuentra en el sitio
              <a
                href="https://iveconference.org"
                target="_blank"
                class="underline"
                >iveconference.org</a
              >.
            </p>
          </article>
        </section>
        <p class="mt-6 text-center">
          <router-link
            :to="{ name: 'previos' }"
            class="bg-c_darkmagenta hover:bg-c_lightmagenta p-2 rounded text-gray-50"
          >
            ver otros números
          </router-link>
        </p>
      </div>
    </article>
  </section>
</template>

<style @scoped>
section#newsletter {
  @apply shadow-none bg-white;
}
section#newsletter article > p {
  @apply text-xs;
  line-height: 1.3rem;
}
section#newsletter article h2 {
  @apply text-xl mt-8 mb-2;
}
section#newsletter article h3 {
  @apply text-base mt-8 mb-2;
}
section#newsletter article a {
  @apply text-c_darkmagenta underline;
}
</style>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "JulioView",
  components: {
    HeaderCoil,
  },
  data() {
    return {
      title: "",
      urlEN: "https://coil.unam.mx/#/coilaborando/previos/julio",
    };
  },
};
</script>
