<template>
  <section
    class="w-4/5 md:w-2/3 mx-auto bg-white text-c_lightblue shadow-xl rounded-sm"
  >
    <header-coil :title="title" :urlEN="urlEN" class="text-c_darkorange" />
    <div class="p-4">
      <router-link :to="{ name: 'resources' }">
        <font-awesome-icon icon="fa-solid fa-circle-chevron-left" />
        Volver a Recursos sobre COIL
      </router-link>
    </div>
    <div class="md:grid md:grid-cols-2">
      <article
        v-for="resource in resources"
        :key="resource.name"
        class="p-4 mb-6"
      >
        <a
          class="block border-b pb-1"
          :href="resource.url"
          :title="resource.name"
          target="_blank"
        >
          <p>{{ resource.name }}</p>
          <p class="text-xs text-c_darkblue mt-2">{{ resource.authors }}</p>
        </a>
      </article>
    </div>
  </section>
</template>

<style scoped>
article a {
  @apply text-c_lightorange;
}
</style>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "InfographicsView",
  components: {
    HeaderCoil,
  },
  data() {
    return {
      title: "Infografías sobre COIL",
      urlEN: "https://coil.unam.mx/en/#/resources/infographics",
      resources: [
        {
          name: "COIL steps at UDEM",
          authors: "Universidad de Monterrey",
          url: "https://drive.google.com/file/d/1hQRlUupCQWT-AfcsRaN2j4EJ9bXFAgcq/view?usp=drive_link",
        },
        {
          name: "Competencies of COIL Virtual Exchange Coordination",
          authors: "Florida International University",
          url: "https://drive.google.com/file/d/1Zmno0vAFf3AuVHNsaeLgs6OPeC6yh6t_/view?usp=drive_link",
        },
        {
          name: "El impacto de los cursos de negocios a través de colaboración en línea para la internacionalización del Currículo",
          authors: "Martín Pantoja Aguilar, Edward Goodman",
          year: 2021,
          url: "https://drive.google.com/file/d/13mw3HAs84bIcPtf3IiTF7MeitTVKYWQd/view?usp=drive_link",
        },
        {
          name: "Enfermería y Covid-19: La voz de sus protagonistas",
          authors:
            "Zárate Grajales, Rosa Amarilis; Ostiguín Meléndez, Rosa María; Castro, Ana Rita; Valencia Castillo, Francisco Blas",
          year: 2020,
          url: "http://www.librosoa.unam.mx/handle/123456789/3201",
        },
        {
          name: "Global citizenship - Appendix-Studies GCE1981-2020",
          authors: "Guimaraes y Finardi",
          year: 2021,
          url: "https://drive.google.com/file/d/1PetkxeNhApBwZfttMHm_wAOWgqTbG14K/view?usp=drive_link",
        },
      ],
    };
  },
};
</script>
