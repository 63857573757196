const url = "https://semanahddata.cuaieed.unam.mx/api/";
const token =
  "57e2ea725e9117aac2743dda83ed764133236b0718b52fa8aa7d7b312e21fe8205434f2a6836dfb31f4020f0f7db1281c244d9d21512fa73829213c534156f730b413f4c7163614859b37504217b29ac4102f6eb7ef0ea5dd695fdb192928a9486bdf0791181db078a9e5412d6acf14293caa3d33b0c1184322d847017c3b199";

async function postDemo(proposalData, imageData) {
  const formData = new FormData();
  formData.append("files", imageData, `${imageData.name}`);
  let response = await fetch(`${url}upload`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "post",
    body: formData,
  }).then((res) => res.json());

  const imageId = {
    associated_image: response[0].id,
  };
  const demoData = Object.assign(proposalData, imageId);
  const fileData = {
    data: demoData,
  };

  return await fetch(`${url}demos`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "post",
    body: JSON.stringify(fileData),
  })
    .then((res) => res.json())
    .then((res) => res.data);
}

async function postProposal(proposalData, imageData) {
  let fileData = {};

  if (imageData) {
    const formData = new FormData();
    const newFilename = setFilename(imageData.name);
    formData.append("files", imageData, newFilename);
    let response = await fetch(`${url}upload`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "post",
      body: formData,
    }).then((res) => res.json());
    const imageId = {
      associated_image: response[0].id,
    };
    fileData = {
      data: Object.assign(proposalData, imageId),
    };
  } else {
    fileData = {
      data: Object.assign(proposalData),
    };
  }

  return await fetch(`${url}events`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "post",
    body: JSON.stringify(fileData),
  })
    .then((res) => res.json())
    .then((res) => res.data);
}

async function getTitleEvents() {
  return await fetch(
    `${url}events?filters[published]=true&fields[0]=title_event`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((res) => res.json());
}

async function postBio(bioData, imageData) {
  let fileData = {};

  if (imageData) {
    const formData = new FormData();
    const newFilename = setFilename(imageData.name);
    formData.append("files", imageData, newFilename);
    let response = await fetch(`${url}upload`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "post",
      body: formData,
    }).then((res) => res.json());
    const imageId = {
      photo: response[0].id,
    };
    fileData = {
      data: Object.assign(bioData, imageId),
    };
  } else {
    fileData = {
      data: Object.assign(bioData),
    };
  }

  return await fetch(`${url}comissions`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "post",
    body: JSON.stringify(fileData),
  })
    .then((res) => res.json())
    .then((res) => res.data);
}

function getPeople() {
  return fetch(`${url}comissions?populate=*&sort[0]=name`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .then((res) => res.data);
}

function setFilename(fileName) {
  const nameArray = fileName.split(".");
  return `${nameArray[0]}_${parseInt(Math.random() * 1000000)}.${
    nameArray[nameArray.length - 1]
  }`;
}

async function getEvents(year) {
  return await fetch(
    `${url}events?populate=*&filters[published][$eq]=true&filters[createdAt][$gte]=${year}-01-01&filters[createdAt][$lte]=${year}-12-31&sort[0]=datetime&pagination[pageSize]=100`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((res) => res.json())
    .then((res) => res.data);
}

export default {
  postDemo,
  postProposal,
  getTitleEvents,
  getEvents,
  postBio,
  getPeople,
};
