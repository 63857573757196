<template>
  <section class="col w-full mt-10" id="newsletter">
    <header class="text-right">
      <p class="text-c_gold">Número 14, agosto - septiembre de 2024</p>
    </header>
    <article>
      <h2>Editorial</h2>
      <h3>COIL y la tradición de educación abierta y a distancia en la UNAM</h3>
      <h4 class="text-right text-sm text-c_darkyellow">
        por Mariana Mujica y Carlos Maza
      </h4>
      <p>
        Durante las últimas semanas el universo COIL en la UNAM ha recibido un
        impulso que casi podríamos calificar de “desbocado” (como se dice de un
        caballo al galope que de repente ya no se puede detener): en la
        Coordinación de Universidad Abierta y Educación a Distancia (CUAED) se
        instaló una oficina de internacionalización, a cargo de la Maestra Tere
        González, quien inmediatamente emprendió lo que podríamos llamar una
        gesta de coordinaciones COIL, con resultados realmente sorprendentes. Y
        es que el Sistema de Universidad Abierta y Educación a Distancia
        (SUAyED), que se coordina y se gestiona desde la CUAED, tiene
        características, herramientas y componentes que hacen que sea natural la
        realización de COIL en ese entorno ya consolidado de educación en línea.
        El SUAyED es muestra de que la tecnología no sólo tiene capacidad para
        transformar industrias, sino también para mejorar la calidad de vida de
        las personas.
      </p>
      <p>
        El SUAyED está resultando fundamental en la expansión de los cursos COIL
        en la UNAM, facilitando la transformación digital que permite a la UNAM
        proyectarse globalmente, gracias a la participación de docentes que ya
        manejan las herramientas de la educación a distancia y el intercambio
        virtual.
      </p>
      <p>
        La combinación COIL-SUAyED permite avanzar nuestro proyecto de una
        educación globalizada, accesible, flexible, multicultural, solidaria y
        comprometida sin importar la ubicación geográfica de los estudiantes. Si
        SUAyED ya aporta elementos para la democratización del acceso a la
        educación superior, en sinergia con COIL se enriquece con el elemento
        internacional y complementa esta apertura al ofrecer oportunidades de
        colaboración que fomentan las perspectivas global, intercultural e
        interdisciplinarias propias del intercambio virtual. Esto crea un modelo
        educativo innovador que promueve la colaboración internacional y el
        aprendizaje activo, adaptándose a las necesidades y realidades actuales
        de los estudiantes.
      </p>
      <p>
        La educación superior en sistema abierto y a distancia es un universo en
        el que la UNAM es pionera en México y, ahora, en su intersección con la
        metodología COIL, ésta fuerte tradición universitaria se coloca a la
        vanguardia mundial. En este momento podemos hablar de una etapa de
        cambio en algunas de las características de la educación superior global
        y, sobre todo, globalizada.
      </p>
    </article>
    <article>
      <h2>Reseña</h2>
      <h3>
        Internacionalización virtual para aumentar el acceso a experiencias
        internacionales
      </h3>
      <p>
        Bruhn, E. (2018). Virtual Internationalization to Increase Access to
        International Experience. In L. E. Rumbley & H. de Wit (Eds.),
        Innovative and Inclusive Internationalization: Proceedings of the
        WES-CIHE Summer Institute June 20–22, 2018 Boston College (pp. 31–33).
        Boston College Center for Higher Education.
        <a href="http://www.jstor.org/stable/resrep39587.15" target="_blank"
          >http://www.jstor.org/stable/resrep39587.15</a
        >
      </p>
      <p class="my-6">Explorando la Internacionalización Virtual</p>
      <p>
        Imagina un mundo donde las fronteras físicas se desdibujan, y los
        salones de clases en los rincones más remotos del planeta se fusionan en
        un solo espacio virtual. Este es el universo que el texto nos invita a
        explorar, un lugar donde la internacionalización virtual no es solo un
        concepto, sino una revolución en la educación superior.
      </p>
      <p>
        En una era donde los viajes pueden ser tan costosos como los sueños de
        un astronauta y las fronteras tan imponentes como murallas de castillo,
        la internacionalización virtual surge como el héroe inesperado de
        nuestra narrativa educativa. El texto nos guía a través de este
        fascinante ciberespacio, mostrando cómo la tecnología puede hacer que la
        experiencia internacional sea accesible para todos, sin importar el
        tamaño de la cuenta bancaria o la ubicación geográfica.
      </p>
      <p>
        El desafío comienza desde la desigualdad en el acceso a la educación
        superior. En un giro inesperado, descubrimos que la educación
        transnacional (TNE) y la internacionalización en casa (IaH) son las
        herramientas que están remodelando el paisaje académico. Pero,
        ¡atención! A pesar de sus promesas, la TNE puede ser más cara que los
        cursos locales, lo que plantea una paradoja interesante: la
        internacionalización que pretende igualar el acceso, a veces, puede
        exacerbar las desigualdades.
      </p>
      <p>
        El texto revela a los pioneros digitales que están haciendo realidad la
        promesa de la educación global. Los proyectos de movilidad virtual, como
        el COIL, actúan como puentes entre estudiantes de diferentes países,
        transformando el aula tradicional en un espacio sin fronteras. Imagina
        asistir a una conferencia en línea desde un aula invertida, participar
        en una pasantía virtual o colaborar en proyectos internacionales sin
        salir de tu casa. La magia de las TIC hace que estos sueños sean
        realidad, conectando estudiantes y profesores de maneras que antes solo
        eran posibles en la ciencia ficción.
      </p>
      <p>
        Pero también revela las limitaciones actuales. Las aspiraciones de crear
        campus virtuales con miles de estudiantes internacionales pueden parecer
        más utópicas que reales debido a barreras persistentes como las
        lingüísticas y culturales. Sin embargo, los programas colaborativos en
        línea y las credenciales alternativas están prosperando, abriendo nuevas
        avenidas para la educación global.
      </p>
      <p>
        Sin embargo, debemos seguir explorando y expandiendo el potencial de la
        internacionalización virtual y tenemos que entender que la educación
        virtual no es un mero premio de consolación, sino una puerta abierta a
        nuevas oportunidades para todos los estudiantes, especialmente para
        aquellos que enfrentan barreras significativas.
      </p>
    </article>
    <article>
      <h2>Testimonios</h2>
      <p>
        La Dra. Aurora Piñeiro organizó dos seminarios semestrales en la
        modalidad COIL en el marco de la Cátedra de Estudios Irlandeses de la
        Facultad de Filosofía y Letras: “Eavan Boland-Anne Enright” en
        coordinación con las Cátedras de Estudios Irlandeses de la Universidad
        de Sao Paulo, Brasil; la Universidad de la Pampa, Argentina y la
        Universidad del Salvador de Buenos Aires, Argentina. Estas son las 4
        cátedras de estudios irlandeses en América Latina. En cuanto a la
        calendarización, se respetó el horario del semestre de la UNAM. El
        primer curso se dictó en el 2022 y el segundo en el 2023. Las sesiones
        eran de dos horas.
      </p>
      <p>
        Los alumnos participantes fueron de varios Colegios de la Facultad, y de
        las universidades participantes, se tuvo una asistencia muy nutrida,
        hubo 80 personas inscritas y que asistieron de manera regular a las
        sesiones sincrónicas. En 2022 se iniciaron estas cátedras en
        conmemoración del centenario de la independencia de Irlanda; en la
        primera ocasión se impartieron clases tanto en inglés como en español y
        en el 2023 se acordó que el curso completo sería dictado en inglés, ya
        que los estudiantes brasileños dijeron que les fue muy difícil entender
        las sesiones en español. Participaron cinco profesores de cada
        universidad que impartieron las sesiones. Los trabajos se aceptaron
        tanto en inglés como en español.
      </p>
      <p>
        La Dra. Piñeiro comenta que fue una experiencia sumamente enriquecedora
        y que los alumnos disfrutaron la interacción. Este es un COIL que se
        planeó sin saber que se estaba empleando esta metodología, una vez que
        se terminó la actividad y se socializó nos dimos cuenta que es un claro
        ejemplo de que la interacción entre académicos se da forma espontánea.
        Creemos firmemente que con una mayor difusión de la metodología las
        experiencias de trabajo internacional, que se daban de manera natural,
        serán aún más benéficas para los estudiantes y docentes.
      </p>
    </article>
    <article>
      <h2>Noticias / Eventos</h2>
      <h3>International Education Week 2024</h3>
      <p>
        En el marco de la “International Education Week 2024” el 14 de noviembre
        a las 09:00 horas (horario de la CDMX) se llevará a cabo un Webinar que
        busca discutir formas innovadoras para la internacionalización del
        currículum y la incorporación de los objetivos de desarrollo sustentable
        en los intercambios virtuales y proyectos COIL.
      </p>
      <p class="my-4">
        <img
          src="@/assets/images/coilaborando/2024/agosto/iw-ods.png"
          alt="Visita del equipo COIL de la UNAH a la UNAM"
          class="mx-auto max-w-lg block"
        />
      </p>
      <p>
        Este webinar promete dar luz sobre las perspectivas internacionales en
        referencia a la integración de los Objetivos de Desarrollo Sustentable
        en el currículum y por ende, en la preparación de nuestros estudiantes
        para una ciudadanía global.
      </p>
      <p class="text-right">
        <a
          href="https://aacu.zoom.us/webinar/register/3017268415989/WN_x9iYcGmyT5a6FfI3NF20cw#/registration"
          target="_blank"
          >Link de registro</a
        >
      </p>
      <h3>LatAm COIL</h3>
      <p>
        LatAm COIL está promocionando un taller para Coordinadores COIL el cual
        puede ser una gran oportunidad para los interesados en esta modalidad de
        trabajo de internacionalización.
      </p>
      <p class="my-4">
        <img
          src="@/assets/images/coilaborando/2024/agosto/workshop.jpg"
          alt="Visita del equipo COIL de la UNAH a la UNAM"
          class="mx-auto max-w-lg block"
        />
      </p>
      <p class="text-right">
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSfXK0P3ocNIAAogNCSRdSbDnZW9PIwIePc4SrC_-UMM9TFwLA/viewform"
          target="_blank"
          >Link de registro</a
        >
      </p>
    </article>
  </section>
</template>

<style scoped>
h2 {
  margin-bottom: 0;
}
</style>

<script>
export default {
  name: "AgostoView",
  components: {},
  data() {
    return {
      title: "",
      urlEN: "https://coil.unam.mx/#/coilaborando/previos/2024/agosto",
    };
  },
};
</script>
