<template>
  <section
    class="w-4/5 md:w-2/3 mx-auto bg-white text-c_lightblue shadow-xl rounded-sm"
  >
    <header-coil :title="title" :urlEN="urlEN" />
    <article class="flex bg-gray-100">
      <div class="col m-10 bg-white w-full p-6">
        <img
          src="@/assets/images/coilaborando/2023/diciembre.jpg"
          class="img-fluid"
          alt=""
        />
        <div class="col text-center pt-10">
          <h1>COILaborando</h1>
          <h2 class="mb-6">Boletín Informativo</h2>
        </div>
        <section class="col w-full mt-10" id="newsletter">
          <header class="text-right">
            <p class="text-c_gold">Número 7, diciembre de 2023</p>
          </header>
          <article>
            <h2>Editorial</h2>
            <h3>¡Muchas gracias!</h3>
            <p>
              Culminamos el semestre y el año con el gusto de haber avanzado en
              las tareas de difusión y capacitación de las metodologías COIL
              para el intercambio virtual en la UNAM, y nos llevamos lo
              realizado como bandera para enfrentar los retos que nos esperan en
              el futuro inmediato. Gracias al solidario y comprometido trabajo
              colegiado de la Comisión COIL formada por integrantes de la Red
              Universitaria de Responsables de Internacionalización (RURI), la
              UNAM tiene presencia en algunas de las redes más activas en la
              promoción del intercambio virtual, como son LATAM COIL y COIL
              Connect, y gracias al apoyo incondicional de nuestras sedes en el
              extranjero, vamos logrando también establecer proyectos de
              intercambio con algunas de las IES cuya colaboración resulta
              estratégica para el desarrollo de la UNAM en el contexto educativo
              internacional.
            </p>
            <p>
              La Comisión COIL de la UNAM se reunió cada jueves, desde el 16 de
              marzo hasta el 7 de diciembre, y lo seguirá haciendo una vez que
              se inicie el semestre 2024-II, el próximo enero. Desde esas
              reuniones se formuló el trabajo de difusión y capacitación, y se
              diseñó el sitio en internet del programa
              <a
                href="https://coil.unam.mx"
                target="_blank"
                rel="noopener noreferrer"
                >COIL UNAM</a
              >, que hemos ido alimentando mes a mes con testimonios, materiales
              de consulta, tutoriales y con la producción de este boletín
              mensual que alcanza ya su número 7.
            </p>
            <p>
              La Comisión COIL, en estrecha colaboración con la Dirección
              General de Cooperación e Internacionalización (DGECI) y con la
              Coordinación de Universidad Abierta, Innovación Educativa y
              Educación a Distancia e (CUAIEED), y con el decidido apoyo de la
              Coordinación de Relaciones y Asuntos Internacionales (CRAI), llevó
              a cabo exitosamente la Primera Jornada COIL UNAM 2023, en la que
              contamos con el experto global más importante en el área, y
              creador de las metodologías COIL, el Dr. Jon Rubin, que volvió a
              los Estados Unidos llevándose una grata impresión por el
              compromiso de la UNAM con las estrategias de internacionalización
              basadas en el intercambio virtual. En 2024 realizaremos la segunda
              y en esta ocasión buscaremos informar también al alumnado de la
              UNAM sobre las posibilidades de internacionalización por medio del
              intercambio virtual COIL.
            </p>
            <p>
              Por todo lo anterior, no nos queda más que decir ¡Gracias! a todas
              las personas que han estado cerca de nuestro trabajo a favor del
              intercambio virtual en la UNAM. Gracias a todas las personas que
              integran la Comisión COIL; gracias a funcionarios y funcionarias y
              a las dependencias de la UNAM que han asumido el reto con
              nosotros; gracias a los especialistas internacionales que nos han
              acompañado, a los alumnos y alumnas del programa UNAMigo por su
              apoyo, y especialmente gracias a las maestras y a los maestros que
              se interesan por estas estrategias de internacionalización sin
              salir de casa para enriquecer a la Universidad de la Nación.
            </p>
            <p class="my-4">
              <img
                src="@/assets/images/coilaborando/comision-coil.png"
                alt="DuocUC"
                class="mx-auto"
              />
            </p>
            <p class="text-center text-xs">
              Integrantes de la Comisión COIL, de la Subdirección de Enlace
              Institucional de la DGECI y de la CRAI, con el Dr. Jon Rubin y el
              Mtro. Gerardo Reza, titular de la DGECI, en la comida después de
              la Primera Jornada COIL UNAM 2023.
            </p>
          </article>
          <article>
            <h2>Reseña: evento</h2>
            <h3>
              Virtual Exchange for Internationalisation at Home: The role of
              social presence in online interactions
            </h3>
            <p>
              El pasado 6 de diciembre se llevó a cabo el webinario:
              <em
                >Intercambio virtual para la internacionalización en casa: el
                papel de la presencia social en las interacciones en línea</em
              >, convocado por el Center of Internationalization of Education
              Brazil-Australia, en el que la Dra. Müge Satar, académica de la
              Universidad de Newcastle, Reino Unido, presentó sus reflexiones en
              torno a la importancia de la presencia social para las
              interacciones en línea, haciendo énfasis, a través de ejemplos, en
              que en la medida en que los participantes de los cursos puedan
              expresar sus emociones, inquietudes y experiencias, el diálogo
              será más fluido e intercultural, lo que facilitará el trabajo
              colaborativo y el aprendizaje.
            </p>
            <p class="my-4">
              <img
                src="@/assets/images/coilaborando/virtual-exchange.png"
                alt="DuocUC"
                class="mx-auto"
              />
            </p>
          </article>
          <article>
            <h2>Reseña: artículo</h2>
            <h3>¿Quién dirige a quién y cómo?</h3>
            <p class="text-right">
              <a
                href="https://www.iienetworker-digital.com/iieb/0223_fall_2023/MobilePagedArticle.action?articleId=1913409#articleId1913409"
                target="_blank"
                rel="noopener noreferrer"
                >Who is Leading Whom and How?</a
              >
            </p>
            <p>
              Jon Rubin, pionero de los COIL que ya conocemos bien, indaga sobre
              un lugar común que nos acompaña al echar a andar proyectos de
              internacionalización académica en las IES: “[…] se requiere un
              liderazgo fuerte para crecer y sostener [un programa de
              internacionalización]. Pero, ¿las formas del liderazgo cambian de
              un lugar a otro?, ¿cómo adaptarlas a través de diferentes
              contextos culturales?”. Dado que las prácticas de liderazgo
              cambian, si bien la sinergia entre ellas puede producir
              “compromiso, guía y respeto”, también se corre el riesgo de
              generar una relación asimétrica, donde la hegemonía se encuentre
              en uno de los lados. “Las diferencias culturales llevan a estilos
              locales de liderazgo que pueden producir malentendidos entre los
              participantes”, dice Rubin, y propone el coliderazgo como
              solución. La metáfora que utiliza el autor es musical: un COIL
              exitoso “se desarrolla como una banda de jazz, donde el
              protagonismo pasa de un miembro a otro”. Para hacer un COIL
              debemos pensar en “cómo se forma un ensamble”, más que en
              completar tareas concretas.
            </p>
            <p>
              En este sentido, un coordinador COIL debe ser dinámico y, de
              preferencia, estar ubicado en posiciones de decisión (arriba en la
              jerarquía), de modo que su participación pueda ser más efectiva:
              debemos “vincular nuestros más altos ideales académicos con
              asociaciones internacionales bilaterales significativas, y
              sostenibles”. Y desde ahí, el autor describe cómo la plataforma
              COIL Connect que él dirige aborda esta problemática y promueve un
              espacio de diálogo y reflexión sobre ello.
            </p>
          </article>
          <article>
            <h2>Próximos eventos</h2>
            <h3>
              Taller de diseño e implementación de Intercambio
              Virtual/Aprendizaje Internacional Colaborativo en Línea (COIL)
            </h3>
            <p class="text-right">Convoca: De Paul University</p>
            <p class="text-right">Fechas: Febrero 2024</p>
            <p>
              Muchas instituciones de educación superior aprovechan los medios
              virtuales para promover experiencias internacionales como una
              forma de llegar a más estudiantes, de forma más equitativa. El
              Intercambio Virtual/Aprendizaje Internacional Colaborativo en
              Línea (COIL) es una pedagogía bien establecida, con un número
              creciente de participantes en todo el mundo. El éxito de estos
              proyectos requiere de una sólida formación de los profesores que
              facilitan los cursos COIL, así como una cuidadosa planificación de
              los cursos.
            </p>
            <p>
              El taller de formación en Intercambio Virtual/Aprendizaje
              Internacional Colaborativo en Línea (COIL), de tres semanas de
              duración, se ofrece en formato virtual. El participante deberá
              comprometerse a realizar una serie de actividades en línea,
              asíncronas, que incluyen lecturas y debates. Las actividades se
              estructuran en cuatro módulos, que requieren un compromiso de
              aproximadamente veinte horas, incluidas tres reuniones
              sincrónicas, de entre 1 y 2 horas.
            </p>
            <p class="text-right">
              <a
                href="https://grad.depaul.edu/portal/cpe?cmd=program&programid=2c0f8888-2836-c99a-6c51-b58136c4f623"
                target="_blank"
                rel="noopener noreferrer"
                >Más información</a
              >
            </p>
            <h3>COIL Virtual Exchange Leadership Institute</h3>
            <p>
              La Universidad Internacional de Florida (FIU, siglas en inglés),
              ha convocado a la participación en este seminario para la
              profesionalización de las actividades de coordinación COIL.
              Funcionarios de la DGECI e integrantes de la Comisión COIL
              postularon para participar en el encuentro, y luego de un complejo
              proceso de dictaminación, han sido aceptados. El periodo de
              postulaciones ya se cerró, lo que viene es compartir con la
              Comisión en pleno, con docentes, responsables de
              internacionalización y autoridades, los aprendizajes que se
              generarán en esta importante actividad, a llevarse a cabo de
              manera virtual en febrero-marzo de 2024.
            </p>
            <p class="text-right">
              <a
                href="https://global.fiu.edu/coil//matching-training/virtual-exchange-leadership-institute/index.html"
                target="_blank"
                rel="noopener noreferrer"
                >Más información</a
              >
            </p>
            <p class="my-4">
              <img
                src="@/assets/images/coilaborando/coil-fiu.png"
                alt="COIL Virtual Exchange Leadership Institute"
                class="mx-auto"
              />
            </p>
          </article>
          <article>
            <h3>Algunos cursos COIL recientes en la UNAM</h3>
            <ul>
              <li>
                El Mtro. Pedro Alarcón Gómez lideró dos nutridos grupos para
                desarrollar el COIL “Business Strategy Simulation” en que
                colaboró la Facultad de Contaduría y Administración con la
                Escuela de Negocios de la Universidad de Sussex (Reino Unido),
                con la mediación de la Sede de la UNAM en ese país;
                febrero-marzo de 2023.
              </li>
              <li>
                La Mtra. Adriana Roque del Ángel impartió el COIL “Diseño y
                evaluación curricular” enlazando a la FES Acatlán con el
                Instituto Tecnológico Universitario Cordillera (Ecuador),
                febrero-mayo de 2023.
              </li>
              <li>
                El Dr. Francisco Eduardo Velázquez Tolsá del Posgrado en Derecho
                desarrolló el COIL “Responsabilidad administrativa y
                resarcitoria”, en colaboración con la Pontifica Universidad
                Católica de Paraná (Brasil), abril-octubre de 2023.
              </li>
              <li>
                La Dra. Guadalupe Vadillo, directora del Bachillerato a
                Distancia de la UNAM, responsable de internacionalización en la
                CUAIEED y coordinadora de la Comisión COIL, desarrolló el COIL
                “Prácticas supervisadas de Psicología”, enlazando a la Facultad
                de Estudios Superiores Iztacala con la Universidad Nacional
                Autónoma de Honduras, septiembre-octubre de 2023.
              </li>
              <li>
                La Mtra. Carmen Rosette llevó a cabo el COIL “Taller de
                principios, técnicas y materiales utilizados en la escultura” en
                colaboración desde la Facultad de Artes y Diseño con la Escuela
                de Artes Plásticas de la Universidad de Costa Rica. La
                experiencia culminó con la visita de las docentes costarricenses
                a México gracias a un apoyo PAECI. El COIL se llevó a cabo en
                septiembre-octubre de 2023.
              </li>
            </ul>
            <p class="text-right text-sm my-12">
              <strong
                >Elaborado por la Comisión COIL de la Red Universitaria de
                Responsables de Internacionalización (RURI) de la UNAM, con
                participación especial de Eréndira Peniche, Caridad Rangel,
                Guadalupe Vadillo y Carlos Maza.</strong
              >
            </p>
          </article>
        </section>
        <p class="mt-6 text-center">
          <router-link
            :to="{ name: 'previos' }"
            class="bg-c_darkmagenta hover:bg-c_lightmagenta p-2 rounded text-gray-50"
          >
            ver otros números
          </router-link>
        </p>
      </div>
    </article>
  </section>
</template>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "DiciembreView",
  components: {
    HeaderCoil,
  },
  data() {
    return {
      title: "",
      urlEN: "https://coil.unam.mx/#/coilaborando/previos/diciembre",
    };
  },
};
</script>
