<template>
  <section>
    <header-coil :title="title" :urlEN="urlEN" class="text-c_lightorange" />
    <div class="md:grid md:grid-cols-2 lg:grid-cols-3">
      <div class="p-6">
        <img
          src="@/assets/images/presence/coil-fi.jpg"
          alt=""
          class="zoomable"
        />
        <a
          href="https://www.comunicacionfi.unam.mx/mostrar_nota.php?id_noticia=3073"
          target="_blank"
          rel="noopener noreferrer"
        >
          <h3>Aprendizaje colaborativo internacional</h3>
          <p>
            Estudiantes de la FI-UNAM y de la Pontificia Universidad Católica
            del Ecuador exponen proyectos finales COIL.
          </p>
          <p class="text-right text-xs">Fotografía: Eduardo Martínez Cuautle</p>
        </a>
      </div>
      <div class="p-6">
        <iframe
          class="w-full aspect-video"
          width="469"
          height="833"
          src="https://www.youtube.com/embed/m7ZI2IvrxqQ"
          title="¿Qué son los cursos COIL?"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div class="p-6">
        <iframe
          class="w-full aspect-video"
          width="469"
          height="833"
          src="https://www.youtube.com/embed/a4FSyYhKHVQ"
          title="La experiencia COIL"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div class="p-6">
        <img src="@/assets/images/coil-cartel.png" alt="" class="zoomable" />
      </div>
      <div class="p-6">
        <img
          src="@/assets/images/coil-infografia.jpg"
          alt=""
          class="zoomable"
        />
      </div>
    </div>
  </section>
</template>

<script>
import mediumZoom from "medium-zoom";
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "PresenceView",
  components: {
    HeaderCoil,
  },
  mounted() {
    const zoom = mediumZoom(".zoomable");
    this.$once("hook:beforeDestroy", () => {
      zoom.detach();
    });
  },
  data() {
    return {
      title: "COIL en los medios",
      urlEN: "https://coil.unam.mx/en/#/presence",
    };
  },
};
</script>
