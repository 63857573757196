<template>
  <section>
    <header-coil :title="title" :urlEN="urlEN" />
    <div class="md:grid md:grid-cols-2 lg:grid-cols-3 items-center">
      <div class="p-6">
        <router-link
          :to="{ name: 'articles' }"
          class="bg-c_darkblue hover:bg-c_lightblue"
        >
          <img src="@/assets/images/publicaciones.jpg" alt="México" />
          <p>Artículos</p>
        </router-link>
      </div>
      <div class="p-6">
        <router-link
          :to="{ name: 'books' }"
          class="bg-c_darkyellow hover:bg-c_yellow"
        >
          <img src="@/assets/images/libros.jpg" alt="México" />
          <p>Libros</p>
        </router-link>
      </div>
      <div class="p-6">
        <router-link
          :to="{ name: 'infographics' }"
          class="bg-c_darkorange hover:bg-c_lightorange"
        >
          <img src="@/assets/images/infografia.jpg" alt="México" />
          <p>Infografias</p>
        </router-link>
      </div>
      <div class="p-6">
        <router-link
          :to="{ name: 'tutorial' }"
          class="bg-c_darkmagenta hover:bg-c_lightmagenta"
        >
          <img src="@/assets/images/tutorial.jpg" alt="Tutorial" />
          <p>Tutorial</p>
        </router-link>
      </div>
      <div class="p-6">
        <router-link
          :to="{ name: 'workbook' }"
          class="bg-c_darkmagenta hover:bg-c_lightblue"
        >
          <img src="@/assets/images/workbook.jpg" alt="Tutorial" />
          <p>Cuadernillo de trabajo para estructurar mi COIL</p>
        </router-link>
      </div>
      <div class="p-6">
        <router-link
          :to="{ name: 'some' }"
          class="bg-c_darkblue hover:bg-c_darkorange"
        >
          <img src="@/assets/images/varios.jpg" alt="México" />
          <p>Varios</p>
        </router-link>
      </div>
    </div>
  </section>
</template>

<style scoped>
a {
  @apply text-gray-50 inline-block text-center;
}
</style>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "ResourcesView",
  components: {
    HeaderCoil,
  },
  data() {
    return {
      title: "Recursos sobre COIL",
      urlEN: "https://coil.unam.mx/en/#/resources",
    };
  },
};
</script>
