<template>
  <section
    class="w-4/5 md:w-2/3 mx-auto bg-white text-c_lightblue shadow-xl rounded-sm"
  >
    <header-coil :title="title" :urlEN="urlEN" />
    <article class="flex bg-gray-100">
      <div class="col m-10 bg-white w-full p-6">
        <img
          src="@/assets/images/coilaborando/2024/mayo/cover.jpg"
          class="img-fluid"
          alt=""
        />
        <div class="col text-center pt-10">
          <h1>COILaborando</h1>
          <h2 class="mb-6">Boletín Informativo</h2>
        </div>
        <section class="col w-full mt-10" id="newsletter">
          <header class="text-right">
            <p class="text-c_gold">Número 12, mayo de 2024</p>
          </header>
          <article>
            <h2>Editorial</h2>
            <p>
              El mes de mayo ha sido de locura para muchas personas y no somos
              la excepción. Por ello, esta edición del boletín sale más tarde de
              lo debido y no incluye testimonios de experiencias COIL en la UNAM
              (que nos ha sido imposible recabar) ni noticias sobre eventos que
              se habrían vencido al publicarse esta nota. Sin embargo, sí
              queremos contar que la promoción de la metodología COIL sigue
              avanzando en la UNAM y cada vez tenemos más experiencias y
              personas interesadas.
            </p>
            <p>
              Entre lo que consideramos relevante informar están los resultados
              de la III Conferencia Regional de Educación Superior de América
              Latina y el Caribe, CRES+5, en la que se abordó el tema del
              intercambio virtual como un elemento estratégico en el proceso de
              internacionalización de las universidades latinoamericanas.
            </p>
            <p>
              E incluimos también una interesante reseña de un artículo
              académico sobre "aulas compartidas globales", como estrategia para
              el desarrollo de competencias sostenibles en la educación
              superior.
            </p>
          </article>
          <article>
            <h2>Reseñas</h2>
            <h3>Declaración de la CRES+5</h3>
            <h5 class="text-right">Paola Mendieta</h5>
            <p>
              Reunión de seguimiento a la III Conferencia Regional de Educación
              Superior de América Latina y el Caribe (CRES+5). La Declaración
              contiene elementos que fortalecerían el intercambio virtual y el
              lugar de la educación superior en el sistema educativo general
              latinoamericano.
              <a
                href="https://cres2018mas5.org/wp-content/uploads/2024/04/Declaracion-CRES5_ES.pdf"
                target="_blank"
                rel="noopener noreferrer"
                >Aquí para su consulta</a
              >.
            </p>
            <p class="my-4">
              <a
                href="https://cres2018mas5.org/wp-content/uploads/2024/04/Declaracion-CRES5_ES.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="@/assets/images/coilaborando/2024/mayo/cres5.png"
                  alt="Introducción a COIL UNAM (DGECI)"
                  class="mx-auto max-w-lg block"
                />
              </a>
            </p>
            <p>
              La Declaración CRES+5 destaca la importancia de ampliar y
              profundizar la integración regional de América Latina y el Caribe
              mediante la internacionalización de la educación superior. Se
              reconocen los avances logrados, como la ratificación de nuevos
              acuerdos para el reconocimiento de títulos por algunos países y el
              desarrollo de buenas prácticas en el reconocimiento de créditos,
              competencias y títulos múltiples por parte de varias universidades
              y redes. Sin embargo, se considera que los avances son limitados e
              insuficientes.
            </p>
            <p>
              Para impulsar la integración regional, se plantean las siguientes
              prioridades esenciales:
            </p>
            <ul>
              <li>
                Crear una agencia regional del conocimiento con financiamiento
                público que diseñe y fomente programas colaborativos y
                solidarios de investigación, posgrado, innovación y extensión en
                temas relevantes para la región.
              </li>
              <li>
                Desarrollar un amplio programa inclusivo de movilidad,
                capacitación e intercambio académico que integre la experiencia
                de redes y asociaciones universitarias, atendiendo a toda la
                comunidad académica.
              </li>
              <li>
                Integrar estas actividades al Espacio Latinoamericano y Caribeño
                de Educación Superior (ENLACES), que tendrá un rol central en
                proponer y monitorear acreditaciones y actividades en los
                programas públicos regionales.
              </li>
              <li>
                Avanzar en el reconocimiento de títulos y diplomas a través del
                nuevo acuerdo regional y una mayor integración entre los
                gobiernos y las instituciones educativas.
              </li>
              <li>
                <em
                  >Ampliar el uso de las tecnologías educativas y el intercambio
                  virtual como forma de impulsar la internacionalización en
                  casa, reforzando la autonomía y soberanía digital.</em
                >
              </li>
              <li>
                Fortalecer las colaboraciones con otros bloques regionales,
                basadas en principios de cooperación solidaria, recíproca y
                horizontal.
              </li>
              <li>Promover políticas lingüísticas inclusivas.</li>
              <li>
                Atender las cuestiones específicas de la cooperación y el
                tránsito transfronterizos para crear ecosistemas educativos
                internacionalizados.
              </li>
            </ul>
            <p>
              Se destaca la importancia del
              <em
                >uso de tecnologías y el intercambio virtual como medios para
                ampliar la internacionalización</em
              >
              en casa y reforzar la autonomía y soberanía digital de los países
              de la región; va alineado con algunos de los proyectos publicados
              en el Plan de Desarrollo Institucional 2023-2027.
            </p>
            <p>
              En referencia al eje central de nuestra comisión, la Declaración
              también sugiere el uso de las tecnologías educativas, el
              intercambio virtual y la internacionalización en casa como medios
              para impulsar la internacionalización y la integración regional de
              la educación superior en América Latina y el Caribe.
            </p>
            <ul>
              <li>
                La internacionalización en casa a través de las TIC se presenta
                como una estrategia fundamental para potenciar las virtudes de
                la tecnología en materia de inclusión y flexibilización,
                obteniendo experiencias de aprendizaje más equitativas,
                personales y significativas.
              </li>
              <li>
                El desarrollo de la multimodalidad, con modalidades híbridas que
                combinen lo presencial y virtual, requerirá inversión, revisión
                y normalización por parte de las instituciones de educación
                superior.
              </li>
              <li>
                Se enfatiza el uso de plataformas en línea, contenidos
                digitales, evaluaciones asíncronas y virtuales para dar
                continuidad a los programas académicos, una lección aprendida de
                la pandemia.
              </li>
              <li>
                Las tecnologías se conciben como herramientas clave para
                fomentar la cooperación, movilidad e intercambio académico
                virtual entre países, complementando la movilidad física.
              </li>
            </ul>
            <h3>El modelo de aula de aprendizaje compartido global</h3>
            <h5 class="text-right">Dagny Valadez</h5>
            <p>
              Membrillo-Hernández, J., Cuervo Bejarano, W. J., Mejía Manzano, L.
              A., Caratozzolo, P., & Vázquez Villegas, P. (2023). Global Shared
              Learning Classroom Model: A Pedagogical Strategy for Sustainable
              Competencies Development in Higher Education.
              <em>International Journal of Engineering Pedagogy (iJEP)</em>,
              13(1), 20–33
            </p>
            <p class="my-4 text-center">
              <img
                src="@/assets/images/coilaborando/2024/mayo/coil-methodology.png"
                alt="COIL methodology implemented in this work"
                class="mx-auto max-w-lg block"
              />
              <span class="block"
                >COIL methodology implemented in this work</span
              >
            </p>
            <p>
              Si se encuentran interesados en conocer ejemplos de cómo se
              implementa un COIL y cuáles son algunas de sus ventajas en la
              educación superior, este artículo es ideal. Los autores comienzan
              por definir Aula Global y el reconocimiento de esta estrategia
              para fomentar el desarrollo de habilidades que permitan a los
              alumnos desenvolverse en un ambiente de educación internacional.
              Su principal meta es llevar a la sociedad a alcanzar los Objetivos
              de Desarrollo Sustentable marcados en la Agenda 2030. La pregunta
              que siempre surge es: ¿Cómo el uso de una estrategia didáctica
              puede servir para llevar al desarrollo sustentable?
            </p>
            <p>
              Este artículo presenta dos casos en los que vemos el desarrollo de
              cursos COIL basados en aprendizaje por retos y la
              transversalización de habilidades parar el siglo XXI. Un punto que
              considero muy valioso es que los autores señalan la importancia de
              la evaluación y proponen distintas opciones para cuantificar los
              resultados. Así mismo, hablan de la importancia de la motivación.
              Me parece muy valioso que los alumnos no reciben solo una
              calificación, sino que se les otorga un badge como forma de
              reconocimiento.
            </p>
            <p>
              Recomiendo esta lectura porque no sólo da ideas de la aplicación
              de los cursos COIL en Aulas Globales, sino que también crea
              caminos para plantear nuevas preguntas en torno a la educación, la
              internacionalización y las oportunidades didácticas que nos
              ofrecen estas formas de interacción académica.
            </p>
          </article>
        </section>
        <p class="mt-6 text-center">
          <router-link
            :to="{ name: 'previos' }"
            class="bg-c_darkmagenta hover:bg-c_lightmagenta p-2 rounded text-gray-50"
          >
            ver otros números
          </router-link>
        </p>
      </div>
    </article>
  </section>
</template>

<style scoped>
a.inline-block.bg-c_darkyellow {
  @apply my-4 rounded-sm;
  text-decoration: none !important;
}
ul li {
  @apply list-disc text-xs ml-6 my-2;
}
</style>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "MayoView",
  components: {
    HeaderCoil,
  },
  data() {
    return {
      title: "",
      urlEN: "https://coil.unam.mx/#/coilaborando/previos/2024/mayo",
    };
  },
};
</script>
