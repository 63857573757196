<template>
  <section
    class="w-4/5 md:w-2/3 mx-auto bg-white text-c_lightblue shadow-xl rounded-sm"
  >
    <header-coil :title="title" :urlEN="urlEN" />
    <article class="flex bg-gray-100">
      <div class="col m-10 bg-white w-full p-6">
        <img
          src="@/assets/images/coilaborando/2023/junio.jpg"
          class="img-fluid"
          alt=""
        />
        <div class="col text-center pt-10">
          <h1>COILaborando</h1>
          <h2 class="mb-6">Boletín Informativo</h2>
        </div>
        <section class="col w-full mt-10" id="newsletter">
          <header class="text-right">
            <p class="text-c_gold">Número 1, junio de 2023</p>
          </header>
          <article>
            <h2>Editorial</h2>
            <h3>Una estrategia COIL para la UNAM</h3>
            <p class="text-right">
              Por Sandra Centeno, Caridad Rangel y Carlos Maza
            </p>
            <p class="my-4">
              <img
                src="@/assets/images/coilaborando/editorial.jpg"
                alt="The Guide to COIL Virtual Exchange"
                class="mx-auto"
              />
            </p>
            <p>
              Profesores, profesoras y especialistas de instituciones de
              educación superior (IES) en todo el mundo coinciden en la
              necesidad de ampliar los límites nacionales de los procesos de
              enseñanza en un mundo cada vez más interconectado y demandante de
              competencias multiculturales y de desarrollo global. Esta
              ampliación de la educación superior aprovecha el desarrollo
              tecnológico que ha hecho de nuestra época la era de las redes.
              Especialmente después de la crisis global de salud que representó
              la pandemia por COVID-19, las tecnologías han mostrado su enorme
              utilidad para la educación.
            </p>
            <p>
              Internacionalizar la educación superior no se reduce a formar
              personas en competencias técnicas que les permitan desarrollarse
              en el mundo profesional, sino también a la responsabilidad de las
              IES de proporcionar herramientas hoy imprescindibles para asumir
              una ciudadanía global: competencias como el reconocimiento y la
              convivencia en la multiculturalidad y actitudes como el respeto al
              otro y la escucha, hoy son indispensables en el entorno
              globalizado.
            </p>
            <p>
              Si la crisis sanitaria tuvo alguna ventaja, fue la de colocar el
              intercambio virtual en el centro de las políticas de
              internacionalización académica, limitada hasta ahora por la
              necesidad de emprender el viaje al extranjero, lo que reducía la
              proporción del alumnado que se beneficiaba de experiencias
              académicas internacionales. El intercambio virtual, especialmente
              en el estilo que adoptan las metodologías pedagógicas agrupadas en
              la estrategia COIL —siglas en inglés de Aprendizaje Colaborativo
              Internacional en Línea— aparece hoy entre la comunidad académica
              global como una herramienta insustituible para amplificar los
              impactos de la educación internacional y crecer en el número de
              alumnos y alumnas (y sus docentes) que viven experiencias de
              internacionalización.
            </p>
            <p>
              IES de todo el planeta están adoptando la estrategia COIL que se
              ha ido estableciendo como estándar para el intercambio virtual,
              dado que supera a otras estrategias por su énfasis en la
              colaboración. Los cursos COIL permiten trabajar en diferentes
              niveles: los docentes se involucran activamente para identificar
              en sus planes de estudio coincidencias o áreas de diálogo y
              emprender procesos internacionales de interacción. Por su lado, el
              alumnado no asume el papel de un receptor pasivo, sino que se
              involucra con sus pares en otro país para crear nuevo contenido,
              desprendido de los planes de estudios originales y, en el camino,
              nutrirse con las virtudes y los desafíos de una experiencia
              multicultural internacional.
            </p>
            <p>
              COIL es una estrategia fácil de implementar gracias a su
              naturaleza flexible. Puede realizarse incluso sin necesidad de que
              exista un convenio previo entre ambas IES (aunque la recomendación
              principal es que la información fluya y cuente con la autorización
              y el apoyo de las autoridades académicas). Funciona así: un
              profesor o profesora en la UNAM vincula a su clase con un grupo en
              otro país que vive una situación similar. Previamente, ambos
              docentes han establecido puntos en común que sus grupos en
              interacción podrán desarrollar durante una parte de su semestre (o
              durante todo el ciclo), y generan actividades de investigación o
              desarrollo creativo que sus alumnos realizarán en grupos mixtos
              mediante tareas colaborativas. Al terminar el módulo COIL, cada
              docente realiza la evaluación de sus propios alumnos, que obtienen
              así, además del crédito correspondiente, un aprendizaje y una
              experiencia internacionales.
            </p>
            <p>
              Las características de la UNAM —sus dimensiones, su diversidad, su
              dispersión en el territorio, así como los principios rectores de
              autonomía y libertad de cátedra— han obligado a imprimir un ritmo
              más cuidadoso en la adopción de la estrategia COIL, en comparación
              con IES de todo el mundo que parecen avanzar de forma mucho más
              ágil, especialmente en América Latina.
            </p>
            <p>
              La promoción de la estrategia COIL entre nosotros enfrenta retos
              específicos que buscamos resolver con el apoyo de la Red
              Universitaria de Responsables de Internacionalización (RURI); un
              tejido que enlaza a cada una de nuestras entidades académicas y
              administrativas con los objetivos universitarios de
              internacionalización académica. Al interior de esta red se ha
              instalado una <strong>Comisión COIL UNAM</strong> encargada de
              promover la adopción de las estrategias COIL y amplificar con
              ellas el alcance de la internacionalización académica en la UNAM.
            </p>
            <p>
              La Comisión COIL de la RURI se ha planteado los objetivos de
              difundir activamente entre la comunidad universitaria las
              características, bondades y metodología de los cursos COIL, y
              acompañar a los y las docentes interesados en el proceso de diseño
              e implementación de los cursos. La intención es lograr que cada
              entidad académica de la UNAM pueda ofrecer al menos un curso COIL
              en el semestre 2024-1 o lo tenga planeado a fin de 2023 para
              desarrollarlo durante el ciclo 2024-2.
            </p>
            <p>
              Con estos fines en mente, la Comisión COIL ha formado grupos o
              subcomisiones que abordan dos ejes principales de acción: la
              formación de docentes en el manejo de estas estrategias de
              intercambio virtual y la difusión de sus posibilidades a lo largo
              y ancho de la UNAM. El boletín
              <strong>COIL<em>aborando</em></strong
              >, que hoy publica su número inaugural, enlaza ambas funciones: es
              una invitación al conocimiento de las metodologías y pedagogías
              involucradas en la estrategia COIL, con lo que cumple un papel
              formativo, y, a la vez, un medio de difusión transmisor de buenas
              prácticas y experiencias exitosas, mediante las cuales se realiza
              una intensa campaña orientada a extender su presencia en nuestra
              práctica educativa.
            </p>
            <p>
              <strong>COIL<em>aborando</em></strong> aparecerá cada mes con
              notas breves sobre buenas prácticas de intercambio virtual,
              consejos sobre herramientas y actividades que se pueden utilizar
              en el desarrollo de un COIL, noticias sobre eventos y actividades
              ya sucedidas o por venir y reseñas de artículos académicos y otros
              productos que aborden COIL.
            </p>
            <p>
              Así, maestra, maestro, te damos la bienvenida a este entorno de
              oportunidades de internacionalización académica, y te invitamos a
              seguir la información y, por supuesto, a echar a andar un módulo
              COIL en tu materia.
            </p>
          </article>
          <article>
            <h2>Reseña</h2>
            <p>
              Harvey Charles, "The Limits of International Education Mobility
              and the Emergence of COIL"
            </p>
            <p class="text-right">Por Dagny L. Valadez</p>
            <div class="flex flex-row my-4">
              <div class="div w-1/4">
                <img
                  src="@/assets/images/coilaborando/the_limits_international_education_mobility.jpg"
                  alt="The Guide to COIL Virtual Exchange"
                  class="img-fluid"
                />
              </div>
              <div class="div w-3/4 px-4">
                <p>
                  <em
                    >Existe un cuerpo de conocimientos relacionados con los COIL
                    que crece rápidamente. Sin embargo, faltaba un texto general
                    que abordara la temática de forma integral. En 2022 la
                    editorial Stylus lanzó al mercado un libro que puede
                    considerarse como “la guía definitiva a COIL”. Editado por
                    los creadores de esta aproximación al intercambio virtual
                    —Jon Rubin, Sarah Guth, Stephanie Doscher y Carrie Prior—,
                    The Guide to COIL Virtual Exchange (La guía al Intercambio
                    virtual COIL) contiene artículos que describen, explican,
                    promueven y capacitan a docentes y gestores en esta
                    aproximación. <strong>COILaborando</strong> difundirá
                    algunos de sus contenidos mediante breves reseñas. Una
                    presentación del libro escrita por Jon Rubin se puede
                    consultar en la revista UNAM Internacional, no. 3, accesible
                    en:
                    <a
                      href="https://revista.unaminternacional.unam.mx/nota/3"
                      target="_blank"
                      class="underline"
                      >revista.unaminternacional.unam.mx</a
                    >.</em
                  >
                </p>
              </div>
            </div>
            <p>
              El artículo brinda un amplio panorama sobre la implementación de
              cursos COIL. El autor nos provee de una variedad de términos
              relacionados con la movilidad que funcionan como palabras clave
              para adentrarse en el mundo de la educación para el siglo XXI.
              Comienza su texto haciendo un recorrido por la historia de la
              movilidad estudiantil, desde la Edad Media hasta hoy; menciona las
              virtudes de la formación académica internacional y su importancia
              para el intercambio de ideas. Redondea esta revisión explicando
              que la principal desventaja de la movilidad física es su carácter
              clasista, ya que, debido al costo de las matrículas, del
              transporte y del hospedaje, sólo está al alcance de sectores
              privilegiados. El artículo presenta el COIL como una estrategia de
              internacionalización que busca fomentar la diversidad, la igualdad
              y la inclusión, propiciando la “internacionalización en casa”,
              además de mencionar su carácter amigable con el planeta.
            </p>
            <p>
              Además de ofrecernos una reflexión sobre los beneficios de los
              cursos COIL y su importancia en el impulso del conocimiento
              global, también da algunas pautas para su implementación de manera
              institucional. Propone algunas características que serían
              deseables en la persona responsable de implementar la estrategia;
              habla de la importancia de los profesores y de la necesidad de
              reconocer su trabajo en esta área para que realmente sea un
              proyecto exitoso.
            </p>
            <p>
              Para quien está interesado en conocer más sobre la
              internacionalización en casa y las puertas que se pueden abrir a
              través de los cursos COIL, esta es una lectura enriquecedora que
              permite reflexionar sobre los contextos en los que se está
              desarrollando la educación y los problemas globales a los que se
              enfrentarán nuestros estudiantes en un futuro no muy lejano.
            </p>
            <p class="my-3">
              Referencia: Charles, H. (2022). The Limits of International
              Education Mobility and the Emergence of COIL. En Rubin, J. y Guth,
              S (Eds.).
              <em
                >The Guide to COIL Virtual Exchange. Implementing, Growing, and
                Sustaining Collaborative Online International Learning</em
              >, pp. 19-31. Sterling: Stylus Publishing.
            </p>
          </article>
          <article>
            <h2>Evento</h2>
            <h3>Tercer Congreso LATAM COIL</h3>
            <p class="my-4">
              <img
                src="@/assets/images/coilaborando/tercer_latam_coil.png"
                alt="Tercer Congreso LATAM COIL"
                class="mx-auto"
              />
            </p>
            <p>
              Esta red Latinoamericana especializada en COIL, de la que la UNAM
              forma parte, es nuestro primer universo de interacción, en
              particular por estar conformada casi en su totalidad por
              universidades de países hispanohablantes. Es un hecho que el
              idioma privilegiado para la colaboración internacional es el
              inglés (la variante llamada “neutra”), pero, cuando nuestras
              competencias en este idioma no son suficientes, nuestro ámbito de
              colaboración más inmediato está en español y, en gran medida,
              agrupado en la red LATAM COIL.
            </p>
            <p>
              Cada año esta red convoca a un congreso en el que se analizan los
              avances, los retos, las experiencias exitosas y los obstáculos al
              desarrollo de COIL en nuestra región. La tercera edición de este
              congreso se llevará a cabo del 12 al 16 de junio de 2023 en
              modalidad cien por ciento virtual. Las inscripciones están
              abiertas hasta el 31 de mayo, y a los integrantes de la Comisión
              COIL en la UNAM nos da mucho gusto informar que participaremos con
              una breve exposición de la estrategia que estamos diseñando para
              nuestra universidad: el programa COIL UNAM y la Comisión COIL en
              la RURI.
            </p>
          </article>
        </section>
        <p class="mt-6 text-center">
          <router-link
            :to="{ name: 'previos' }"
            class="bg-c_darkmagenta hover:bg-c_lightmagenta p-2 rounded text-gray-50"
          >
            ver otros números
          </router-link>
        </p>
      </div>
    </article>
  </section>
</template>

<style @scoped>
section#newsletter {
  @apply shadow-none bg-white;
}
section#newsletter article p {
  @apply text-xs;
  line-height: 1.3rem;
}
section#newsletter article h2 {
  @apply text-xl mt-8 mb-2;
}
section#newsletter article h3 {
  @apply text-base mt-8 mb-2;
}
</style>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "JunioView",
  components: {
    HeaderCoil,
  },
  data() {
    return {
      title: "",
      urlEN: "https://coil.unam.mx/#/coilaborando/previos/junio",
    };
  },
};
</script>
