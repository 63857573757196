<template>
  <section
    class="w-4/5 md:w-2/3 mx-auto bg-white text-c_lightblue shadow-xl rounded-sm"
  >
    <header-coil :title="title" :urlEN="urlEN" class="text-c_darkyellow" />
    <div class="p-4">
      <router-link :to="{ name: 'resources' }">
        <font-awesome-icon icon="fa-solid fa-circle-chevron-left" />
        Volver a Recursos sobre COIL
      </router-link>
    </div>
    <div class="md:grid md:grid-cols-2">
      <article
        v-for="resource in sortedResources"
        :key="resource.name"
        class="p-4 mb-6"
      >
        <a
          class="block border-b pb-1"
          :href="resource.url"
          :title="resource.name"
          target="_blank"
        >
          <p>{{ resource.name }}</p>
          <p class="text-xs text-c_darkblue mt-2">{{ resource.authors }}</p>
          <p class="text-right text-xs">{{ resource.year }}</p>
        </a>
      </article>
    </div>
  </section>
</template>

<style scoped>
article a {
  @apply text-c_darkyellow;
}
</style>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "SomeView",
  components: {
    HeaderCoil,
  },
  data() {
    return {
      title: "Varios sobre COIL",
      urlEN: "https://coil.unam.mx/en/#/resources/various",
      resources: [
        {
          name: "Reimaginar juntos nuestros futuros: un nuevo contrato social para la educación",
          year: 2021,
          authors: "UNESCO",
          url: "https://unesdoc.unesco.org/ark:/48223/pf0000379381_spa",
        },
        {
          name: "Reimagining our futures together: a new social contract for education",
          year: 2021,
          authors: "UNESCO",
          url: "https://unesdoc.unesco.org/ark:/48223/pf0000379707",
        },
        {
          name: "New report “Moving minds: Opportunities and challenges for virtual student mobility in a post-pandemic world”",
          year: 2022,
          authors: "UNESCO IESALC",
          url: "https://www.iesalc.unesco.org/en/2022/02/27/new-report-moving-minds-opportunities-and-challenges-for-virtual-student-mobility-in-a-post-pandemic-world/",
        },
        {
          name: "Ley Federal del Derecho de Autor México",
          year: 2018,
          authors: "Diario Oficial de la Federación",
          url: "https://www.indautor.gob.mx/documentos/marco-juridico/leyfederal.pdf",
        },
      ],
    };
  },
  computed: {
    sortedResources() {
      const resourcesCopy = this.resources.slice();
      resourcesCopy.sort((a, b) => b.year - a.year);
      return resourcesCopy.sort((a, b) => {
        if (a.year < b.year) {
          return 1;
        } else if (a.year > b.year) {
          return -1;
        } else {
          if (a.authors < b.authors) {
            return -1;
          } else if (a.authors > b.authors) {
            return 1;
          } else {
            return 0;
          }
        }
      });
    },
  },
};
</script>
