<template>
  <Slide right ScaleRotate :closeOnNavigation="true">
    <menu-coil />
  </Slide>
</template>

<script>
import { Slide } from "vue-burger-menu";
import MenuCoil from "@/components/MenuCoil.vue";

export default {
  components: {
    Slide,
    MenuCoil,
  },
};
</script>

<style>
:root {
  --c-white: #f2f2f2;
  --c-gold: #d98e04;
  --c-green: #588c32;
  --c-darkblue: #034c8c;
  --c-lightblue: #3d80d9;
}
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
  cursor: pointer;
}
.bm-burger-bars {
  background-color: var(--c-white) !important;
  box-shadow: 2px 2px rgba(217, 142, 4, 0.5);
}
.line-style {
  position: absolute;
  height: 20%;
  left: 0;
  right: 0;
}
.cross-style {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}
.bm-cross {
  background: #3d80d9 !important;
}
.bm-cross-button {
  height: 0;
  width: 0;
}
.bm-menu {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Stay on top */
  top: 0;
  left: 0;
  background-color: #3d80d9 !important; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /*0.5 second transition effect to slide in the sidenav*/
}
.bm-overlay {
  background: rgba(0, 0, 0, 0) !important;
}
.bm-item-list {
  color: var(--c-white) !important;
}
.bm-item-list > * {
  display: flex;
  text-decoration: none;
}
.bm-item-list > * > span {
  color: white !important;
}
</style>
