<template>
  <section
    class="w-4/5 md:w-2/3 mx-auto bg-white text-c_lightblue shadow-xl rounded-sm"
  >
    <header-coil :title="title" :urlEN="urlEN" />
    <article class="flex bg-gray-100">
      <div class="col m-10 bg-white w-full p-6">
        <img
          src="@/assets/images/coilaborando/2023/septiembre.jpg"
          class="img-fluid"
          alt=""
        />
        <div class="col text-center pt-10">
          <h1>COILaborando</h1>
          <h2 class="mb-6">Boletín Informativo</h2>
        </div>
        <section class="col w-full mt-10" id="newsletter">
          <header class="text-right">
            <p class="text-c_gold">Número 4, septiembre de 2023</p>
          </header>
          <article>
            <h2>Editorial</h2>
            <h3>Primera Jornada COIL UNAM 2023</h3>
            <p class="text-right mb-4">Por Carlos Maza</p>
            <p>
              COIL está creciendo en la UNAM. Más docentes cada vez se enlazan
              con pares en otros países y desarrollan experiencias de
              intercambio virtual basadas en la metodología COIL, pero aún falta
              mucho por hacer, la metodología COIL, sus ventajas y sus
              oportunidades tienen que llegar a todos y todas las docentes de
              nuestra universidad, donde quiera que se encuentren. Es por ello
              que además de la página en internet
              <a
                href="https://coil.unam.mx/"
                target="_blank"
                rel="noopener noreferrer"
                >COIL UNAM</a
              >
              hemos emprendido la organización de este evento de información y
              formación para gestores de internacionalización y para docentes,
              sobre COIL: para que se convierta en una alternativa común,
              permanente, de internacionalización académica en la UNAM.
            </p>
            <p>
              Faltan pocos días para nuestro evento de divulgación de la
              metodología COIL en la UNAM: la Primera Jornada COIL UNAM ya tiene
              todo listo para llevarse a cabo el próximo 19 de octubre. Nos
              reuniremos tanto presencial como virtualmente ese jueves en las
              instalaciones del Centro de Formación y Profesionalización Docente
              de la UNAM, entre las 9:30 y las 14:00 horas, durante las cuales
              responsables de internacionalización, docentes y funcionarios
              universitarios conoceremos los pormenores de la metodología desde
              la perspectiva de su creador, el Dr. Jon Rubin, director de COIL
              Consulting; asistiremos a la presentación de algunas de las
              experiencias COIL más interesantes que se han llevado a cabo
              recientemente en la UNAM y participaremos en talleres sobre esta
              perspectiva innovadora en la educación superior.
            </p>
            <p>
              Si aún no te has registrado para asistir a la Jornada y ser parte
              de esta experiencia, hazlo en el
              <a
                href="https://coil.unam.mx/#/jornada-coil"
                target="_blank"
                rel="noopener noreferrer"
                >siguiente enlace</a
              >.
            </p>
          </article>
          <article>
            <h2>Reseña</h2>
            <h3>Ventajas de la iniciativa COIL</h3>
            <p class="text-right mb-4">Por Adriana Núñez Puertos</p>
            <p class="pl-6">
              Hasnain Bokhari, "<em
                >A cross-cultural, inter-disciplinary experience: The COIL
                initiative</em
              >". Post en el blog <em>The Bulletin Blog</em> de la Escuela Willy
              Brandt de Políticas Públicas, de la Universidad de Erfurt,
              Alemania.
              <a
                href="https://thebulletin.brandtschool.de/a-cross-
cultural-inter-disciplinary-experience-the-coil-initiative"
                target="_blank"
                rel="noopener noreferrer"
                >A cross-cultural, inter-disciplinary experience: The COIL
                initiative</a
              >
            </p>
            <p>
              Publicado originalmente en inglés, el texto analiza el potencial
              de la metodología COIL desde una experiencia sostenida por la
              Escuela Willy Brandt de Políticas Públicas de la Universidad de
              Erfurt, Alemania, con el Colegio de México.
            </p>
            <p>
              Las instituciones de educación superior están adoptando enfoques
              innovadores que permiten ampliar los aprendizajes, como la
              colaboración internacional mediante el uso de tecnologías. El
              objetivo: obtener experiencias educativas globales.
            </p>
            <p>
              La Escuela de Políticas Públicas Willy Brandt de la Universidad de
              Erfurt y el Colegio de México colaboraron en un proyecto COIL como
              parte de la iniciativa de Digitalización e Internacionalización de
              la Escuela Brandt.
            </p>
            <p>
              El Dr. Hasnain Bokhari impartió un curso de posgrado titulado
              "Gobernanza y derechos humanos en la era digital", durante el
              segundo semestre de 2022, dentro del cual se desarrolló un curso
              COIL con estudiantes de ambas instituciones colaborando durante un
              mes para realizar un trabajo sobre "Política de los Influencers en
              las redes sociales".
            </p>
            <p>
              COIL es una metodología innovadora que facilita la colaboración
              internacional entre estudiantes y profesores, promueve el
              intercambio de experiencias e ideas y permite trabajar de manera
              transcultural y transfronteriza. Entre sus objetivos están:
              generar perspectivas globales en los participantes, ampliar el
              intercambio internacional y mejorar los resultados de aprendizaje
              aprovechando las Tecnologías de la Información y la Comunicación
              (TIC).
            </p>
            <p>
              Bokhari, en conjunto con el departamento de Estudios de Género del
              Colegio de México trabajaron para diseñar un COIL sobre
              <em>Social Media Influencers</em>, derechos humanos y perspectiva
              de género. Participaron once estudiantes de la Escuela Willy
              Brandt y siete del Colegio de México, formando cuatro grupos, con
              cada alumno o alumna participando activamente en la resolución de
              tareas diseñadas por ellos mismos.
            </p>
            <p>
              El autor resalta la diversidad cultural que se da en los COIL pues
              permite que el intercambio de ideas sea enriquecedor para los
              participantes; genera debates, manifiesta intereses y produce
              reflexiones sobre el tema que se aborda, que en este caso incluyó
              dos grande áreas: los estudios de género y las políticas públicas.
            </p>
            <p>
              Se incluyen en el texto breves testimonios de los participantes
              que ilustran claramente los elementos positivos de COIL, como:
            </p>
            <ul class="text-xs list-disc ml-6 mt-2">
              <li class="mt-2">
                "Progreso significa cosas distintas en contextos diferentes. La
                actividad COIL permitió aumentar nuestra conciencia sobre
                algunas cosas y sirvió como punto de partida para comprender el
                progreso desde distintas perspectivas", Novita Andari
                (Indonesia).
              </li>
              <li class="mt-2">
                "La actividad COIL enriqueció nuestra experiencia de aprendizaje
                al incorporar la diversidad cultural, lo que nos permitió tener
                discusiones tanto sociales como académicas", Fatima Murawwat
                (Pakistan).
              </li>
              <li class="mt-2">
                "Lo que me gusto de la actividad COIL fue la posibilidad de
                explorer la interseccionalidad de dos disciplinas: los estudios
                de género y las políticas públicas", Angela Zawedde (Uganda).
              </li>
            </ul>
          </article>
          <article>
            <h2>Próximos eventos</h2>
            <h3>IVEC</h3>
            <p>
              Están aún abiertas las inscripciones a este evento que ya habíamos
              anunciado en COILaborando. International Virtual Exchange
              Conference se llevará a cabo en Sao Paulo, Brasil, del 30 de
              octubre al 1 de noviembre de 2023. El
              <a
                href="https://iveconference.org/registration-fees-2023/."
                target="_blank"
                rel="noopener noreferrer"
                >enlace para registro</a
              >.
            </p>
            <h3>
              Presentación de la estrategia de internacionalización de la
              universidad chilena DuocUC
            </h3>
            <p class="my-4">
              <img
                src="@/assets/images/coilaborando/duoc-uc.jpg"
                alt="DuocUC"
                class="mx-auto"
              />
            </p>
            <p>
              Duoc UC (Departamento Universitario Obrero y Campesino,
              Universidad Católica de Chile) lanza una nueva estrategia de
              internacionalización en la que el intercambio virtual es central y
              nos invita al lanzamiento este 11 de octubre a las 14:30 (hora de
              CDMX). Es necesario registrarse antes del evento para obtener la
              liga correspondiente.
            </p>
            <h3>Academia de intercambio virtual de la Iniciativa Stevens</h3>
            <p>
              La fundación Stevens Initiative ha desarrollado junto con COIL
              Consulting una encuesta global sobre intercambio virtual; pronto
              conoceremos los resultados. Mientras tanto, esta misma iniciativa
              convoca a su Academia de Intercambio Virtual, un curso de seis
              semanas que comenzará en enero de 2024 y que será muy interesante
              para gestores de internacionalización en general y de COIL en
              particular. Toda la información en
              <a
                href="https://www.stevensinitiative.org/virtual-exchange-academy/"
                target="_blank"
                rel="noopener noreferrer"
                >el sitio</a
              >. Las inscripciones se cierran el 20 de octubre, así que ¡corre!
            </p>
          </article>
        </section>
        <p class="mt-6 text-center">
          <router-link
            :to="{ name: 'previos' }"
            class="bg-c_darkmagenta hover:bg-c_lightmagenta p-2 rounded text-gray-50"
          >
            ver otros números
          </router-link>
        </p>
      </div>
    </article>
  </section>
</template>

<style scoped>
article a {
  text-decoration: underline;
}
</style>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "SeptiembreView",
  components: {
    HeaderCoil,
  },
  data() {
    return {
      title: "",
      urlEN: "https://coil.unam.mx/#/coilaborando/previos/septiembre",
    };
  },
};
</script>
