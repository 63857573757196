<template>
  <section>
    <header-coil :title="title" :urlEN="urlEN" class="text-c_darkblue" />
    <article class="md:flex md:flex-row">
      <div
        class="bg-coil-about w-full md:ml-5 md:mb-2 bg-no-repeat bg-center bg-cover md:w-1/5 p-10 md:p-0"
      ></div>
      <div class="w-full md:w-4/5 p-4 lg:pl-10 md:pb-20">
        <p>
          Un COIL es una experiencia de intercambio en línea que permite
          movilidad internacional sin salir del país. Se trata de cursos
          curriculares de un programa académico de bachillerato o de
          licenciatura, que se ofrece en línea de forma completa o parcial (solo
          unas semanas del semestre o año) y donde hay al menos dos docentes (al
          menos uno de la UNAM y al menos uno de una universidad extranjera).
        </p>
        <p>Tiene dos componentes fundamentales:</p>
        <ol type="a">
          <li>Contenidos del programa académico correspondiente</li>
          <li>Promoción de competencia intercultural</li>
        </ol>
        <p>
          En estas experiencias, estudiantes y docentes aprenden, discuten y
          colaboran, al introducirse en la cultrua y perspectivas de sus pares
          internacionales.
        </p>
      </div>
    </article>
  </section>
</template>

<style scoped>
h2 {
  @apply text-c_darkmagenta;
}
</style>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "AboutView",
  components: {
    HeaderCoil,
  },
  data() {
    return {
      title: "Acerca de COIL UNAM",
      urlEN: "https://coil.unam.mx/en/#/about",
    };
  },
};
</script>
