<template>
  <section
    class="w-4/5 md:w-2/3 mx-auto bg-white text-c_lightblue shadow-xl rounded-sm"
  >
    <header-coil :title="title" :urlEN="urlEN" />
    <article class="flex bg-gray-100">
      <div class="col m-10 bg-white w-full p-6">
        <img
          src="@/assets/images/coilaborando/2024/febrero/cover.jpg"
          class="img-fluid"
          alt=""
        />
        <div class="col text-center pt-10">
          <h1>COILaborando</h1>
          <h2 class="mb-6">Boletín Informativo</h2>
        </div>
        <section class="col w-full mt-10" id="newsletter">
          <header class="text-right">
            <p class="text-c_gold">Número 9, febrero de 2024</p>
          </header>
          <article>
            <h2>Editorial</h2>
            <p class="mt-4 text-c_darkyellow">
              <em>Instituto de Liderazgo para el Intercambio Virtual</em>
            </p>
            <p>
              Avanza el semestre y se emprenden nuevos intercambios al tiempo
              que continuamos desarrollando y participando en actividades de
              capacitación para fortalecer las actividades de intercambio
              virtual (IV) en la UNAM.
            </p>
            <p>
              Algunos miembros de nuestra comisión COIL asistiremos al diplomado
              “Instituto de Liderazgo para el Intercambio Virtual” que imparte
              la Universidad Internacional de Florida junto con la plataforma
              COIL CONNECT. Se espera obtener de esta experiencia nuevas
              estrategias y mecanismos para alentar a las y los docentes de toda
              la UNAM a emprender intercambios virtuales, especialmente de mayor
              alcance que un webinario simple, por medio de las metodologías
              COIL. La primera sesión será el próximo martes 27 de febrero; el
              equipo de la UNAM, conformado por Guadalupe Vadillo, Dolores
              González-Casanova, Erik Granados y Carlos Maza, ya elaboró su
              presentación en video y se alista para iniciar su participación.
            </p>
            <p class="mt-4 text-c_darkyellow">
              <em>Coordinaciones hacia COIL</em>
            </p>
            <p>
              Diversos proyectos de colaboración se están cocinando desde
              diferentes entidades académicas. El 21 de febrero la Maestra Laura
              Soto, de la Facultad de Filosofía y Letras (también docente en la
              FES Acatlán), se reunió con los coordinadores COIL de la DGECI y
              con el director y funcionarios de UNAM-Boston para preparar la
              respuesta a la invitación que nos hizo un profesor de filosofía de
              la Universidad de Massachusetts, en torno de materias de filosofía
              en las que se estudiarán las perspectivas de “buen vivir”
              presentes en cosmovisiones de los pueblos originarios americanos.
            </p>
            <p>
              A la vez, comenzamos a promover y buscar pares académicos fuera
              del país para cursos que nos han propuesto las facultades de
              Psicología (integración de expediente psicológico) y de Ingeniería
              (inteligencia artificial), proyectos que se suman a los COIL que
              ya se están desarrollando en las facultades de Artes y Diseño, de
              Filosofía y Letras, FES Iztacala y otras entidades.
            </p>
          </article>
          <article>
            <h2>Reseña: artículo</h2>
            <h3 class="text-right">
              Guimaraes, Felipe, y Finardi, Kyria (2021). Global citizenship
              education (GCE)<br />
              in internationalisation: COIL as alternative Thirdspace.
              <em>Globalisation Societies and Education</em>,<br />
              19(5), pp. 1-17. DOI: 10.1080/14767724.2021.1875808
            </h3>
            <p class="text-right">
              Por Dagny Valadez, Facultad de Filosofía y Letras
            </p>
            <p class="my-4 lg:px-20 2xl:px-40">
              <img
                src="@/assets/images/coilaborando/global-citizenship.png"
                alt="Global citizenship education"
                class="mx-auto"
              />
              <span class="text-center text-xs block mt-2"
                >Globalisation Societies and Education</span
              >
            </p>
            <p>
              El artículo “Global Citizenship Education (GCE) in
              Internationalization: COIL as Alternative Thirdspace” (Educación
              para la ciudadanía global en internacionalización: COIL como una
              alternativa de ‘tercer espacio’) de Felipe Furtado Guimarães y
              Kyria Rebeca Finardi nos enfrenta con la urgencia de renovar los
              paradigmas de la educación superior, tomando en cuenta las
              realidades complejas a las que nos enfrentamos y, sobre todo, a
              las que se enfrentarán los estudiantes de hoy.
            </p>
            <p>
              Si bien los retos más urgentes aparecen en la Agenda 2030, con la
              llegada de la pandemia de COVID-19 se hicieron mucho más visibles
              las desigualdades y la importancia de reconfigurar las prácticas
              educativas para preparar a los jóvenes, quienes enfrentarán un
              mundo en cambio rápido y constante.
            </p>
            <p>
              Los autores desarrollan el concepto de “educación para la
              ciudadanía” y nos llevan a darnos cuenta de la deuda que existe
              con la educación, ya que este concepto nace en la década de los
              ochenta y es apenas en estos momentos que hay una verdadera
              discusión sobre la importancia de integrar nuevos elementos en los
              modelos educativos. Se hace énfasis en la importancia de la
              interseccionalidad entre género, lengua, sostenibilidad y
              decolonización para alcanzar los objetivos de la Agenda 2030.
            </p>
            <p>
              Después de presentar los conceptos de educación para la
              ciudadanía, de lo glonacal (global-nacional-local) y de tercer
              espacio, se mencionan las ventajas de utilizar cursos COIL para
              fomentar las habilidades blandas que requieren los alumnos,
              integrar lenguas, culturas y perspectivas distintas de manera más
              democrática e igualitaria. Además, presentan un estudio detallado
              sobre la preminencia de ciertos términos en las bases de datos
              especializadas para demostrar la importancia que ha cobrado el
              estudio de la educación para la ciudadanía en los estudios
              académicos.
            </p>
            <p>
              Este es un texto muy completo que permite tener una visión amplia
              sobre la educación superior actual, las responsabilidades que
              tienen las instituciones de educación superior y las ventajas que
              ofrecen las tecnologías y los cursos COIL para atajar muchos de
              los retos a los que nos enfrentamos.
            </p>
          </article>
          <article>
            <h2>Eventos</h2>
            <h3>Webinarios de Internacionalización en Casa de la UNACH</h3>
            <p class="text-right">
              Por Eréndira Peniche, Centro Peninsular en Humanidades y Ciencias
              Sociales de la UNAM en Mérida, Yucatán
            </p>
            <p class="my-4">
              <img
                src="@/assets/images/coilaborando/flyer-webinarios-feb.jpg"
                alt="Webinarios de Internacionalización en Casa de la UNACH"
                class="mx-auto max-w-lg block"
              />
            </p>
            <p>
              La Universidad Autónoma de Chiapas convoca a los Webinarios
              Internacionalización en Casa, que tienen como objetivo dar a
              conocer metodologías, técnicas, tendencias y casos de éxito de
              programas de Internacionalización en Casa y están dirigidos al
              personal universitario de oficinas de internacionalización,
              profesores y todos aquellos interesados en el tema. La primera
              sesión tuvo lugar el 21 de febrero, y estuvo a cargo de la Dra.
              Verónica Rodríguez Luna, Coordinadora del Programa VIC/COIL de la
              Universidad Veracruzana y Coordinadora de la Red Latinoamericana
              COIL. Al inicio de la sesión se explicó que el término de
              internacionalización en casa fue introducido en 1999, con el
              propósito de desarrollar las competencias internacionales e
              interculturales de los estudiantes, con fines académicos, sin que
              tuvieran que salir de su ciudad. La oportunidad de tener una
              experiencia de internacionalización puede ser más accesible para
              los estudiantes pues, desde casa, con una computadora o
              dispositivo móvil, tienen la oportunidad de aprender a través de
              un trabajo colaborativo, intercultural e internacional,
              características de la internacionalización en casa. Es así como
              surge un nuevo paradigma de enseñanza-aprendizaje, pues desde el
              2006, y liderados por la State University of New York (SUNY), se
              establece la metodología COIL, la cual se caracteriza porque puede
              implementarse con cualquier disciplina; promueve el trabajo
              interdisciplinario y en equipo, así como el estudio activo;
              incluye interacciones de carácter intercultural; puede utilizar
              cualquier plataforma y/o herramienta tecnológica que permita la
              comunicación y la gestión de contenidos y debe durar al menos
              cuatro semanas. Para la implementación de cursos COIL es
              importante la participación de varios actores de la institución:
              rectoría, oficina de relaciones internacionales, equipo COIL y
              principalmente, profesores y estudiantes que participen
              activamente en el proyecto. Al final se abrió un espacio de
              preguntas y respuestas. Las siguientes sesiones de los Webinarios
              Internacionalización en Casa se llevarán a cabo el 28 de febrero,
              y el 6 y el 13 de marzo y están abiertas a todo público.
            </p>
            <p class="text-right">
              Para participar
              <a
                class="inline-block bg-c_darkyellow !text-white p-1"
                href="https://unach-mx.zoom.us/meeting/register/tZUrfuqsqTIsEtcKkd0SEeOqSGttR8m7EMJ7#/registration"
                target="_blank"
                >registrate aquí</a
              >
            </p>
          </article>
        </section>
        <p class="mt-6 text-center">
          <router-link
            :to="{ name: 'previos' }"
            class="bg-c_darkmagenta hover:bg-c_lightmagenta p-2 rounded text-gray-50"
          >
            ver otros números
          </router-link>
        </p>
      </div>
    </article>
  </section>
</template>

<style scoped>
a.inline-block.bg-c_darkyellow {
  @apply my-4 rounded-sm;
  text-decoration: none !important;
}
ul li {
  @apply list-disc text-xs ml-6 my-2;
}
</style>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "FebreroView",
  components: {
    HeaderCoil,
  },
  data() {
    return {
      title: "",
      urlEN: "https://coil.unam.mx/#/coilaborando/previos/febrero",
    };
  },
};
</script>
