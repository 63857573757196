<template>
  <section
    class="w-4/5 md:w-2/3 mx-auto bg-white text-c_lightblue shadow-xl rounded-sm"
  >
    <header-coil :title="title" :urlEN="urlEN" />
    <article class="flex bg-gray-100">
      <div class="col m-10 bg-white w-full p-6">
        <img
          src="@/assets/images/coilaborando/2024/enero/cover.jpg"
          class="img-fluid"
          alt=""
        />
        <div class="col text-center pt-10">
          <h1>COILaborando</h1>
          <h2 class="mb-6">Boletín Informativo</h2>
        </div>
        <section class="col w-full mt-10" id="newsletter">
          <header class="text-right">
            <p class="text-c_gold">Número 8, enero de 2024</p>
          </header>
          <article>
            <h2>Editorial</h2>
            <p>
              Iniciamos 2024 con energías renovadas. En el inicio del rectorado
              del Dr. Leonardo Lomelí Vanegas, las perspectivas del intercambio
              virtual crecen y se presentan como una extraordinaria área de
              oportunidad para amplificar el impacto y el alcance de los
              procesos de internacionalización en la UNAM.
            </p>
            <p>
              Entre marzo de 2023 (cuando se creó la comisión COIL UNAM con la
              participación de 12 personas responsables de internacionalización
              de diversas entidades académicas y dependencias administrativas) y
              el arranque del primer semestre de 2024, hemos alcanzado objetivos
              importantes: desde la puesta en marcha de nuestro
              <router-link :to="{ name: 'home' }" class="text-underline">
                sitio en internet</router-link
              >, con el acercamiento de nuestra casa de estudios al mundo del
              intercambio virtual internacional, hasta la realización de la
              Primera Jornada COIL UNAM 2023, el día 16 de octubre, en la que
              tuvimos oportunidad de sistematizar lo realizado, compartir en
              presencia y a distancia, y comenzar a diseñar el futuro de este
              ámbito de la enseñanza.
            </p>
            <p>
              Entre ambos hitos, gracias a la comisión COIL de la RURI y al
              trabajo desarrollado en la Subdirección de Enlace Institucional de
              la DGECI, hemos ido avanzando en la tarea de dar a conocer las
              metodologías COIL de intercambio virtual y hemos crecido también
              en experiencias concretas de colaboración virtual internacional.
              Tuvimos la oportunidad de mostrar el acercamiento de la UNAM a la
              metodología COIL en el Tercer Congreso de la red LATAM COIL, y
              también hemos avanzado en nuestra propia capacitación como
              gestores y gestoras COIL para la UNAM, gracias a diferentes
              actividades de formación a las que hemos podido asistir.
            </p>
            <p>
              A la vez, aunque aún queda mucho trabajo por hacer para extender
              el uso de las metodologías COIL entre nosotros, avanzamos en el
              establecimiento de colaboraciones muy importantes, como fueron las
              de:
            </p>
            <ul>
              <li>
                Prácticas supervisadas de psicología, entre FES Iztacala y la
                Universidad Nacional Autónoma de Honduras.
              </li>
              <li>
                Responsabilidad administrativa y resarcitoria, entre la Facultad
                de Derecho y el Posgrado en Derecho de la UNAM y la Pontificia
                Universidad Católica de Paraná, Brasil.
              </li>
              <li>
                Diseño y evaluación curricular, entre la FES Acatlán y el
                Instituto Tecnológico Universitario Cordillera, Ecuador.
              </li>
              <li>
                Taller de principios y técnicas en escultura, entre la Facultad
                de Artes y Diseño y la Escuela de Artes Plásticas de la
                Universidad de Costa Rica.
              </li>
              <li>
                Business Strategy Simulation, exitoso COIL entre la Facultad de
                Contaduría y Administración y la Universidad de Sussex, con
                apoyo de la sede de la UNAM en el Reino Unido.
              </li>
            </ul>
            <p>
              Y en cuanto a las tareas de capacitación para la coordinación y
              puesta en marcha de cursos COIL, seguimos llevando charlas de
              inducción a dependencias y entidades académicas, con la finalidad
              de que cada docente de la UNAM, y eventualmente cada alumna, cada
              alumno, sepan que existe esta forma de intercambio virtual
              internacional y que está a su alcance, listo para generar
              experiencias de internacionalización “sin salir de casa”.
            </p>
            <p>
              Mientras escribimos estas palabras, arrancan nuevos cursos COIL en
              la UNAM; entre ellos uno muy especial pues reúne a dos eminencias
              de la escritura y la literatura, como son la Dra. Margarita
              Palacios por la FFyL y el Dr. Antonio Garrido por la Universidad
              Complutense de Madrid, alrededor de la escritura creativa.
            </p>
            <p>
              Por último, hemos iniciado los trabajos para la organización de la
              Segunda Jornada COIL UNAM 2024. Planeada para mediados de octubre,
              deseamos de nueva cuenta realizar un encuentro híbrido, presencial
              y virtual, y enfocarlo muy especialmente hacia el alumnado, con el
              fin de que esté al tanto de las posibilidades de
              internacionalización que COIL representa y se convierta en un
              elemento proactivo en el proceso de adopción generalizada de estas
              metodologías. Contamos con la amable propuesta e invitación de los
              planteles de la UNAM en Mérida, Yucatán (ENES Mérida y CEPHICS),
              pensando en la posibilidad de que la parte presencial del
              encuentro tenga lugar en esa ciudad.
            </p>
            <p>
              Así, damos la bienvenida a la comunidad universitaria a este nuevo
              ciclo en que confiamos que el acercamiento COIL a la
              internacionalización crecerá hacia su consolidación.
            </p>
          </article>
          <article>
            <h2>Próximos eventos</h2>
            <h3>
              Instituto de Liderazgo sobre Intercambio Virtual COIL (COIL
              Virtual Exchange Leadership Institute) de la Universidad
              Internacional de Florida
            </h3>
            <p class="my-4 lg:px-20 2xl:px-40">
              <img
                src="@/assets/images/coilaborando/coilFIU.png"
                alt="DuocUC"
                class="mx-auto"
              />
              <span class="text-center text-xs"
                >Stephanie Doscher, Directora COIL en FIU</span
              >
            </p>
            <p>
              Del 27 de febrero al 9 de abril de 2024 se llevará a cabo este
              evento de formación (a distancia) convocado por una de las
              universidades pioneras y de vanguardia en la adopción y difusión
              de las metodologías COIL de intercambio virtual, la Universidad
              Internacional de Florida (FIU por sus siglas en inglés). Gracias
              al apoyo de la DGECI, de la Facultad de Contaduría y
              Administración y de la CUAIEED, cinco integrantes de la Comisión
              COIL UNAM de la RURI participarán en este diploma.
            </p>
            <p>
              Durante seis semanas se reunirán virtualmente los equipos de 16
              IES de diferentes países, con la finalidad de incorporar las
              metodologías COIL en su currículo y en sus planes de
              internacionalización.
            </p>
            <p>
              Los facilitadores principales del diploma serán dos de las
              personas más comprometidas con COIL en el mundo: el Dr. Jon Rubin,
              creador de esta aproximación pedagógica en la Universidad Estatal
              de Nueva York (SUNY) y fundador de la plataforma COIL Connect, y
              la Dra. Stephanie Doscher, directora de COIL en la FIU y una de
              las personas más entusiastas en torno de COIL global.
            </p>
            <p>
              El equipo de la UNAM que participará en este diploma está
              conformado por los y las responsables de internacionalización de
              la ENES Mérida (Mtro. Erik Granados), CUAIEED (Dra. Guadalupe
              Vadillo), Facultad de Contaduría y Administración (Mtra. Mitzi
              Gómez). Por parte de la DGECI asistirán al diploma la Mtra.
              Dolores González-Casanova, subdirectora de Enlace Institucional, y
              el Lic. Carlos Maza, coordinador de Fomento a la
              Internacionalización.
            </p>
            <p class="text-right">
              <a
                class="inline-block bg-c_darkyellow !text-white p-1"
                href="https://global.fiu.edu/coil//matching-training/virtual-exchange-leadership-institute/index.html"
                target="_blank"
                >Más información</a
              >
            </p>
            <h3>
              Semana internacional de capacitación de personal en la Universidad
              Libre de Berlín (FUB International Staff Training Week 2024)
            </h3>
            <p class="my-4">
              <img
                src="@/assets/images/coilaborando/COILFreie.png"
                alt="DuocUC"
                class="mx-auto"
              />
            </p>
            <p>
              La Universidad Libre de Berlín, Alemania, (FUB) convoca a su
              Semana Internacional de Capacitación de Personal, a llevarse a
              cabo del 10 al 14 de junio de 2024, de manera presencial. Bajo el
              lema “abrazar la movilidad: los que se mueven, los que se quedan”,
              durante cuatro días se abordarán temas relacionados con la
              preparación del alumnado para una experiencia de movilidad,
              incluyendo el enfrentamiento a una cultura diferente por medio del
              involucramiento activo. La tesis central es que no solo quien se
              va enfrenta lo diferente y la otredad, sino también quienes se
              quedan y reciben visitantes de otros lugares.
            </p>
          </article>
          <article>
            <h2>Reseña</h2>
            <h3>
              Reporte de campo de la
              <em
                >Encuesta de la Stevens Initiative sobre intercambio virtual</em
              >
            </h3>
            <p class="my-4">
              <img
                src="@/assets/images/coilaborando/StevensInitiative.png"
                alt="StevensInitiative"
                class="mx-auto"
              />
            </p>
            <p>
              Gracias a nuestros colegas de la red LATAM COIL en la Universidad
              Veracruzana recibimos el reporte final de la encuesta sobre
              intercambio virtual que elaboró la Stevens Initiative, y en la
              cual la DGECI participó a nombre de la UNAM, junto con más de 200
              instituciones entre IES, ONG y empresas o fundaciones relacionadas
              con la educación a distancia de uno u otro modo.
            </p>
            <p class="text-right">
              <a
                class="inline-block bg-c_darkyellow !text-white p-1"
                href="https://www.stevensinitiative.org/resource/2024-survey-of-the-virtual-exchange-field-report/"
                target="_blank"
                >Descarga el informe</a
              >
            </p>
            <p>
              La Iniciativa Stevens (Stevens Initiative) es un emprendimiento
              con base en organizaciones no gubernamentales y busca enlazar
              personas de diversos lugares para conectarlos y generar trabajo
              con las tecnologías de comunicación que son ya comunes para muchos
              y muchas de nosotros. La iniciativa invierte en programas de
              Intercambio virtual, comparte investigación, recursos y buenas
              prácticas en busca de mejorar el impacto del intercambio virtual
              (IV) (<a href="https://www.stevensinitiative.org" target="_blank"
                >ver más</a
              >).
            </p>
            <p>
              El informe de la encuesta 2024 encuentra lo siguiente entre sus
              principales hallazgos:
            </p>
            <ul>
              <li>
                Luego de una disminución pospandemia, el IV recupera niveles de
                2021.
              </li>
              <li>Aumento en el número de ofertas de IV.</li>
              <li>La mitad de los oferentes de IV está fuera de los EUA.</li>
              <li>
                El nivel principal sigue siendo el de estudios superiores
                (licenciatura).
              </li>
              <li>
                IV crece pero encuentra un límite en el aspecto de financiación.
              </li>
            </ul>
            <p class="my-4">
              <img
                src="@/assets/images/coilaborando/encuestaSI-01.png"
                alt="encuestaSI-0"
                class="mx-auto"
              />
            </p>
            <p class="my-4">
              <img
                src="@/assets/images/coilaborando/encuestaSI-02.png"
                alt="encuestaSI-0"
                class="mx-auto"
              />
            </p>
            <p class="my-4">
              <img
                src="@/assets/images/coilaborando/encuestaSI-03.png"
                alt="encuestaSI-0"
                class="mx-auto"
              />
            </p>
            <p class="my-4">
              <img
                src="@/assets/images/coilaborando/encuestaSI-04.png"
                alt="encuestaSI-0"
                class="mx-auto"
              />
            </p>
          </article>
        </section>
        <p class="mt-6 text-center">
          <router-link
            :to="{ name: 'previos' }"
            class="bg-c_darkmagenta hover:bg-c_lightmagenta p-2 rounded text-gray-50"
          >
            ver otros números
          </router-link>
        </p>
      </div>
    </article>
  </section>
</template>

<style scoped>
a.inline-block.bg-c_darkyellow {
  @apply my-4 rounded-sm;
  text-decoration: none !important;
}
ul li {
  @apply list-disc text-xs ml-6 my-2;
}
</style>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "EneroView",
  components: {
    HeaderCoil,
  },
  data() {
    return {
      title: "",
      urlEN: "https://coil.unam.mx/#/coilaborando/previos/enero",
    };
  },
};
</script>
