<template>
  <section
    class="w-4/5 md:w-2/3 mx-auto bg-white text-c_lightblue shadow-xl rounded-sm"
  >
    <header-coil :title="title" :urlEN="urlEN" class="text-c_darkmagenta" />
    <div class="p-4">
      <router-link :to="{ name: 'resources' }">
        <font-awesome-icon icon="fa-solid fa-circle-chevron-left" />
        Volver a Recursos sobre COIL
      </router-link>
    </div>
    <div class="w-11/12 mx-auto">
      <h2 class="text-2xl text-c_darkmagenta">
        Cuadernillo de trabajo para estructurar mi COIL
      </h2>

      <div class="w-11/12 py-10 mx-auto flex flex-row text-center pb-20">
        <div class="w-1/2">
          <p>
            <a href="docs/tallerCOILers.pptx" download="tallerCOILers.pptx"
              ><img src="@/assets/images/ppt.png" alt="" class="mx-auto"
            /></a>
          </p>
        </div>
        <div class="w-1/2">
          <p>
            <a href="docs/tallerCOILers.pdf" download="tallerCOILers.pdf"
              ><img src="@/assets/images/pdf.png" alt="" class="mx-auto"
            /></a>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
article a {
  @apply text-c_darkmagenta;
}
</style>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "SomeView",
  components: {
    HeaderCoil,
  },
  data() {
    return {
      title: "Tutorial COIL",
      urlEN: "https://coil.unam.mx/en/#/resources/various",
      resources: [],
    };
  },
};
</script>
