<template>
  <section
    class="w-4/5 md:w-2/3 mx-auto bg-white text-c_lightblue shadow-xl rounded-sm"
  >
    <header-coil :title="title" :urlEN="urlEN" />
    <article class="flex bg-gray-100">
      <div class="col m-10 bg-white w-full p-6">
        <img
          src="@/assets/images/coilaborando/2023/octubre.jpg"
          class="img-fluid"
          alt=""
        />
        <div class="col text-center pt-10">
          <h1>COILaborando</h1>
          <h2 class="mb-6">Boletín Informativo</h2>
        </div>
        <section class="col w-full mt-10" id="newsletter">
          <header class="text-right">
            <p class="text-c_gold">Número 5-6, octubre-noviembre de 2023</p>
          </header>
          <article>
            <h2>Editorial</h2>
            <p>
              Este número de nuestro boletín <em>COILaborando</em> es doble
              porque en octubre de 2023, el día 19, realizamos la Primera
              Jornada COIL UNAM 2023, y quisimos esperar para brindar a nuestros
              lectores y lectoras una reseña de lo sucedido ese día. Así,
              presentamos este número un poco más extenso que los anteriores,
              pues cubre ese importante encuentro de difusión de las
              metodologías y estrategias de intercambio virtual entre nuestros
              equipos docentes y de coordinación de internacionalización en cada
              una de las entidades académicas e instancias administrativas de la
              UNAM. Desde aquí reiteramos nuestro agradecimiento a todas las
              personas que participaron, de forma presencial y a distancia, en
              esta iniciativa para “aprender por el mundo sin salir de casa” y
              les invitamos a ver el video-memoria del evento en
              <a
                href="https://www.youtube.com/watch?v=CayeYWxNxOo"
                target="_blank"
                rel="noopener noreferrer"
                >YouTube</a
              >.
            </p>
          </article>
          <article>
            <h2>
              Primera Jornada COIL UNAM 2023: aprender por el mundo sin salir de
              casa
            </h2>
            <p>
              Con el apoyo de la Coordinación de Relaciones y Asuntos
              Internacionales (CRAI) y de la Red Universitaria de Responsables
              de Internacionalización (RURI), y con el compromiso de la
              Dirección General de Cooperación e Internacionalización (DGECI) y
              la Coordinación de Universidad Abierta, Innovación Educativa y
              Educación a Distancia (CUAIEED) —las entidades convocantes—, el 19
              de octubre se desarrolló con éxito la
              <em>Primera Jornada COIL UNAM 2023</em>, con el propósito de
              divulgar en la UNAM las metodologías de Aprendizaje Colaborativo
              Internacional en Línea. Fue un encuentro híbrido, presencial y a
              distancia, y contó con la participación de más de 250 personas,
              responsables de internacionalización y docentes de diversas
              entidades académicas.
            </p>
            <p>
              La Jornada se orientó hacia la amplificación del conocimiento
              sobre los cursos COIL y sus posibilidades, que permiten “aprender
              por el mundo sin salir de casa”, como decía el lema del encuentro.
              El objetivo de que cada docente de la UNAM tenga la posibilidad de
              vincular a su grupo de alumnos y alumnas con pares internacionales
              es cada día más cercano gracias a iniciativas como esta.
            </p>
            <p class="my-4 text-center">
              <img
                src="@/assets/images/coilaborando/jornada-coil-grupo.png"
                alt="DuocUC"
                class="mx-auto"
              />
              <em class="text-xs"
                >Foto de grupo de participantes presenciales en la Primera
                Jornada COIL UNAM 2023, en las instalaciones del CFOP</em
              >
            </p>
            <p>
              Luego de la inauguración, a cargo de los titulares de las
              entidades convocantes, vino la conferencia magistral del Dr. Jon
              Rubin, quien años atrás sentó las bases del sistema en la
              Universidad Estatal de Nueva York y que actualmente dirige una
              organización que alienta el intercambio virtual en todo el mundo,
              la plataforma de intercambio virtual COIL Connect. El Dr. Rubin
              enfatizó la capacidad de las metodologías COIL para abrirnos
              realmente a la diferencia y al diálogo con la otredad. Abrir la
              puerta al mundo COIL puede resultar en un verdadero
              enriquecimiento y en auténtico desarrollo de competencias
              globales, interdisciplinarias e interculturales para nuestro
              alumnado en colaboración con sus pares en el extranjero.
            </p>
            <p>
              La segunda actividad de la Jornada fue una mesa redonda coordinada
              por la Dra. Guadalupe Vadillo, directora del Bachillerato a
              Distancia de la UNAM y coordinadora de la Comisión COIL de la
              RURI, reconocida especialista en este campo de la pedagogía.
              Mediante preguntas disparadoras, la Dra. Vadillo dio cauce a la
              presentación de cinco interesantes experiencias COIL sostenidas
              recientemente en la UNAM, en las que han participado especialistas
              de diversas disciplinas que trabajan en distintas entidades
              académicas.
            </p>
            <p>
              Luego de un agradable receso en los espacios abiertos del Centro
              de Formación y Profesionalización Docente de la CUAIEED
              —privilegiado espacio de trabajo en Ciudad Universitaria,
              recientemente inaugurado—, los asistentes, tanto presenciales como
              virtuales, se dividieron entre salones diferentes para asistir a
              uno de los tres talleres simultáneos que incluyó la Jornada:
            </p>
            <ul class="text-xs list-disc ml-6 mt-2">
              <li class="mb-2">
                "Introducción al mundo COIL", a cargo de Carlos Maza,
                coordinador de Fomento a la Internacionalización en la DGECI;
              </li>
              <li class="mb-2">
                "Buenas prácticas de gestión COIL", coordinado por la maestra
                Dolores González-Casanova, subdirectora de Enlace Institucional
                en la DGECI, y
              </li>
              <li class="mb-2">
                "COIL: muchas soluciones para los retos", dirigido por la Dra.
                Vadillo.
              </li>
            </ul>
            <p>
              Por la tarde, una vez terminados los trabajos generales de la
              Jornada, el Dr. Rubin ofreció un taller a la Comisión COIL de la
              RURI, con el fin de brindar a sus integrantes más herramientas
              para la coordinación y seguimiento de cursos COIL. El Dr. Rubin
              celebró los avances en la adopción de la metodología COIL en la
              UNAM y convocó a la DGECI y a la RURI a participar activamente en
              la red COIL Connect que él dirige.
            </p>
            <p>
              Gracias a las actividades desarrolladas en torno de los cursos
              COIL, la UNAM fortalece su proyección internacional y aumenta las
              posibilidades del intercambio académico por la vía virtual.
            </p>
          </article>
          <article>
            <h2>Reseña</h2>
            <h3>
              Internationalization at Home: Enhancing Global Competencies in the
              EFL Classroom through International Online Collaboration
            </h3>
            <p class="text-right mb-4">
              Por Mtra. Dagny Ludmila Valadez Valderrábano
            </p>
            <p>
              Reseña del artículo
              <em
                >Internationalization at Home: Enhancing Global Competencies in
                the EFL Classroom through International Online Collaboration</em
              >
              (Internacionalización en casa: mejora de las competencias globales
              en el aula de Inglés como lengua extranjera a través de la
              colaboración internacional en línea).
            </p>
            <p>
              Este artículo se publicó en <em>Education Sciences</em> en marzo
              del 2023 y nos presenta el proceso de planeación de un curso COIL
              en el 2022. La introducción es muy concreta y si no se está muy
              relacionado con el concepto de COIL, lo define de manera clara y
              menciona cuatro puntos que se consideran fundamentales para
              definir un curso como COIL:
            </p>
            <ol>
              <li>
                Hay colaboración internacional entre individuos con un contexto
                cultural diferente.
              </li>
              <li>
                Hay interacción en línea, ya sea sincrónica o asincrónica.
              </li>
              <li>
                Se busca el desarrollo de perspectivas globales y las
                competencias interculturales de los estudiantes.
              </li>
              <li>
                Debe incluirse un componente de reflexión durante el proceso.
              </li>
            </ol>
            <p>
              Otro elemento que considero relevante es que los autores detallan
              los beneficios que obtienen los alumnos de un curso de este tipo.
              Si bien pareciera que este punto se repite en la explicación del
              marco teórico de la experiencia, la realidad es que la
              contextualización de las bondades del COIL en los marcos
              económicos, políticos, culturales y sociales de la sociedad
              globalizada occidental es muy valiosa, especialmente, cuando
              describe qué son las habilidades blandas, la importancia de
              desarrollarlas debido a que cada vez son más apreciadas en el
              mundo laboral y aún son poco trabajadas en los ambientes
              educativos.
            </p>
            <p>
              El proyecto se llevó a cabo entre la universidad de Aveiro en
              Portugal y La Universidad de la Cámara de Comercio de Tailandia;
              participaron 46 estudiantes de ocho nacionalidades diferentes
              quienes contaban con un nivel B2 en lengua inglesa, de acuerdo al
              Marco Común Europeo de Referencia, y el enfoque pedagógico fue
              centrado en el estudiante, basado en proyectos. El producto final
              del curso era un ensayo de diez páginas en el cual se hacía una
              investigación y reflexión sobre las diferencias culturales entre
              los países de los participantes. Se proveyó a los alumnos de
              lecturas teóricas que les permitieron reflexionar acerca de las
              diversas culturas y sus características; también se les
              proporcionaron algunas preguntas que buscaban favorecer el
              intercambio y que fueron parte del ensayo final. La conclusión del
              artículo se enfoca en las percepciones de los estudiantes y sus
              opiniones respecto al curso y los resultados de los ensayos.
            </p>
            <p>
              En mi opinión, el artículo ofrece una buena guía para alguien que
              está interesado en comenzar un COIL, permite visualizar bien los
              pasos a seguir y entender la metodología para llevarlo a cabo.
            </p>
          </article>
          <article>
            <h3>Reseña del evento eMOVIES, presentación de Syllabus</h3>
            <p>
              El pasado 22 de noviembre del presente, se llevó a cabo, en el
              marco del programa eMOVIES (Espacio de Movilidad Virtual en
              Educación Superior) de la Organización Universitaria
              Interamericana, la presentación de docentes de 10 universidades de
              América Latina, quienes compartieron su experiencia en la
              impartición de cursos COIL. Dichos cursos contaron con la
              participación de profesores y estudiantes de la Universidad
              Pedagógica Nacional Francisco Morazán de Honduras; la Universidad
              de Playa Ancha, de Chile; la Universidad Tecnológica de Panamá; la
              Universidad Técnica Particular de Loja, Ecuador; la Universidad
              Privada Domingo Savio, Bolivia; la Universidad Nacional de Mar del
              Plata Argentina; la Universidad Estatal a Distancia de Costa Rica;
              la Universidad Continental de Perú así como la Universidad EAN y
              la Corporación Universitaria Remington, ambas de Colombia. México
              estuvo representado por la Universidad de Guadalajara, la
              Universidad Autónoma de San Luis Potosí y la Universidad
              Veracruzana.
            </p>
            <p>
              A través de los testimonios de los equipos de profesores que
              participaron, se hizo hincapié en la utilidad de la metodología
              COIL para favorecer las experiencias de internacionalización en
              casa, desarrollar trabajo colaborativo y habilidades de
              comunicación en los estudiantes. Estos últimos comentaron que fue
              una muy grata experiencia participar ya que les permitió adquirir
              competencias que les permitirán tener un mejor desarrollo
              profesional.
            </p>
            <p>
              El registro en video de la actividad de puede consultar en
              <a
                href=" https://www.youtube.com/live/HC7Eu0zKaEg?si=UcntosiKchWte_hA"
                target="_blank"
                rel="noopener noreferrer"
                >YouTube</a
              >.
            </p>
            <p class="my-4 text-center">
              <img
                src="@/assets/images/coilaborando/coil-emovies.png"
                alt="DuocUC"
                class="mx-auto"
              />
              <em class="text-xs"
                >Captura de pantalla de la presentación de uno de los cursos
                COIL en eMOVIES</em
              >
            </p>
          </article>
          <article>
            <h2>Próximos eventos</h2>
            <h3>
              COIL Virtual Exchange Leadership Institute: February 27- April 9,
              2024
            </h3>
            <p>
              <a
                href="https://global.fiu.edu/coil//matching-training/virtual-exchange-leadership-institute/index.html"
                target="_blank"
                rel="noopener noreferrer"
                >Instituto de Liderazgo para el Intercambio Virtual COIL</a
              >
            </p>
            <p>
              Este instituto (curso-taller) en línea, de 6 semanas, reúne a 16
              equipos líderes de instituciones de educación superior,
              estadounidenses e internacionales, para integrar intercambio
              virtual a través de cursos COIL en sus planes de estudio e
              internacionalización.
            </p>
            <p>Con la participación en el Instituto los equipos podrán:</p>
            <ul class="text-xs list-disc ml-6 mt-2">
              <li>
                Evaluar el contexto y la capacidad de su institución para
                mantener y ampliar el intercambio virtual COIL.
              </li>
              <li>
                Identificar las formas en que las diversas partes interesadas y
                unidades del campus colaborarán para apoyar el éxito del
                programa.
              </li>
              <li>
                Conectar con nuevos socios internacionales y vincular las
                asociaciones de intercambio virtual COIL con otras actividades
                de internacionalización.
              </li>
              <li>
                Identificar estrategias clave para facilitar un desarrollo
                profesional eficaz, la evaluación del aprendizaje de los
                estudiantes y la evaluación del programa.
              </li>
            </ul>
          </article>
          <article>
            <h2>Nueva publicación</h2>
            <p class="pl-6">
              Ballesteros-Sola, M., & Magomedova, N. (2023). Impactful Social
              Entrepreneurship Education: A US-Spanish Service Learning
              Collaborative Online International Learning (COIL) project.
              <em>The International Journal of Management Education</em>, 21(3),
              100866.
              <a
                href="https://doi.org/10.1016/j.ijme.2023.100866"
                target="_blank"
                rel="noopener noreferrer"
                >https://doi.org/10.1016/j.ijme.2023.100866</a
              >
            </p>
            <p>
              Abstract: Social Entrepreneurship Education (SEE) is becoming
              ubiquitous across geographical and institutional contexts.
              Although service learning (SL) and global learning (GL) are
              high-impact practices frequently used in SEE, the implementation
              of SL Collaborative International Online Learning (COIL) in this
              context is less explored. This study borrows from the literature
              on SE competencies, SL and GL to address this gap by critically
              describing a US-Spain seven-week SL COIL project, and, through an
              exploratory multi-sourced qualitative "researcher-participants"
              study, generating findings related to the effect of this
              pedagogical tool on the students' competencies. Mixed teams of
              students had to solve a challenge posed by four social enterprises
              in the US and Spain. The findings confirm increased personal,
              social, and cognitive skills, reinforcing the relevance of this
              pedagogy to increasing intercultural competencies, and reducing
              the inequities of traditional study-abroad experiences. As an
              exploratory study, it is limited in its scope, but it provides the
              foundation for further research into COIL practices in SEE.
            </p>
            <p>
              Keywords: Social entrepreneurship education; Experiential
              learning; Service learning; Collaborative online international
              learning (COIL); International communication; Challenge-based
              learning; Intercultural competencies; Course design; Team project
            </p>
          </article>
          <article>
            <h2>
              Cursos COIL con participación de la UNAM durante el semestre
              agosto-diciembre de 2023
            </h2>
            <ul class="text-xs list-disc ml-6 mt-2">
              <li>
                Desde la FES Iztacala, la Dra. Guadalupe Vadillo condujo un COIL
                sobre
                <em
                  >Prácticas supervisadas de psicología (consejería breve
                  centrada en soluciones)</em
                >
                en colaboración con el grupo de la Dra. Anabel de la Rosa de la
                Universidad Autónoma de Honduras. Coordinaron el proyecto entre
                agosto y septiembre y lo desarrollaron del 26 de septiembre al
                29 de octubre.
              </li>
              <li>
                En la Facultad de Derecho, el Dr. Francisco Eduardo Velázquez
                Tolsá compartió un curso de posgrado con su homólogo Dr. Emerson
                Gabardo, de la Pontificia Universidad Católica de Paraná,
                Brasil, sobre
                <em>Responsabilidad administrativa y resarcitoria</em>. Se
                planeó desde noviembre de 2022; en marzo de 2023 se realizó la
                preparación técnica y se llevó a cabo entre el 14 de abril y el
                17 de octubre de 2023.
              </li>
              <li>
                El área de Psicología en la FES Acatlán colaboró con estudiantes
                de la carrera de Talento Humano del Instituto Tecnológico
                Universitario Cordillera, de Ecuador, Por parte de la UNAM el
                curso fue conducido por la Mtra. Adriana Roque del Ángel, y por
                la IES ecuatoriana, la Ing. Friktzia Mendoza Jaramillo, y
                trabajaron sobre<em>Aprendizaje basado en proyectos</em>.
                <p class="my-4 text-center">
                  <img
                    src="@/assets/images/coilaborando/coil-acatlan.png"
                    alt="DuocUC"
                    class="mx-auto"
                  />
                  <em class="text-xs"
                    >Capturas de pantalla del COIL FES Acatlán - Instituto
                    Cordillera, Ecuador</em
                  >
                </p>
              </li>
              <li>
                En la Facultad de Artes y Diseño, la Mtra. Carmen Rosette
                desarrolló de nueva cuenta un COIL que se realiza desde hace
                varios semestres con las maestras Beatriz Parra Thompson e Iria
                Salas de la Escuela de Artes Visuales de la Universidad de Costa
                Rica. En esta ocasión, luego del curso, las maestras
                costarricenses nos visitaron para concluir el proceso de forma
                presencial, gracias a un apoyo PAECI. El tema del COIL es la
                escultura en cerámica,
                <em
                  >Taller de principios, técnicas y materiales utilizados en la
                  escultura</em
                >.
                <p class="my-4 text-center">
                  <img
                    src="@/assets/images/coilaborando/coil-taller-principios.jpg"
                    alt="DuocUC"
                    class="mx-auto"
                  />
                  <em class="text-xs"
                    >Las maestras Iria Salas y Beatriz Parra Thompson de la
                    Universidad de Costa Rica. Entre ellas, Tania Ornelas,
                    responsable de internacionalización de la FAD</em
                  >
                </p>
              </li>
              <li>
                La Facultad de Contaduría y Administración respondió a una
                invitación de la Universidad de Sussex, Inglaterra, gestionada
                por la Sede de la UNAM en el Reino Unido, para participar en un
                COIL sobre
                <em
                  >Simulación de toma de decisiones de negocios
                  internacionales</em
                >. La FCA dispuso dos grupos (80 alumnos) que trabajaron en
                inglés con 120 pares en Londres en equipos que reproducían la
                dinámica real del comercio internacional.
              </li>
            </ul>
          </article>
        </section>
        <p class="mt-6 text-center">
          <router-link
            :to="{ name: 'previos' }"
            class="bg-c_darkmagenta hover:bg-c_lightmagenta p-2 rounded text-gray-50"
          >
            ver otros números
          </router-link>
        </p>
      </div>
    </article>
  </section>
</template>

<style scoped>
article a {
  text-decoration: underline;
}
article ol > li {
  @apply !list-decimal my-2 text-xs;
}
</style>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "OctubreView",
  components: {
    HeaderCoil,
  },
  data() {
    return {
      title: "",
      urlEN: "https://coil.unam.mx/#/coilaborando/previos/octubre",
    };
  },
};
</script>
