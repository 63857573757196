<template>
  <section
    class="w-4/5 md:w-2/3 mx-auto bg-white text-c_lightblue shadow-xl rounded-sm"
  >
    <header-coil :title="title" :urlEN="urlEN" />
    <article class="flex bg-gray-100">
      <div class="col m-10 bg-white w-full p-6">
        <img
          src="@/assets/images/coilaborando/2023/agosto.jpg"
          class="img-fluid"
          alt=""
        />
        <div class="col text-center pt-10">
          <h1>COILaborando</h1>
          <h2 class="mb-6">Boletín Informativo</h2>
        </div>
        <section class="col w-full mt-10" id="newsletter">
          <header class="text-right">
            <p class="text-c_gold">Número 3, agosto de 2023</p>
          </header>
          <article>
            <h2>Editorial</h2>
            <h3>Hacia la Primera Jornada COIL UNAM 2023</h3>
            <p>
              Como parte de las actividades de divulgación y promoción de la
              estrategia pedagógica COIL para el intercambio virtual, la CRAI,
              la CUAIEED y la DGECI, junto a la Comisión COIL de la Red
              Universitaria de Responsables de Internacionalización, organizan
              la Primera Jornada COIL UNAM 2023, un encuentro dirigido a nuestro
              profesorado y a gestores y gestoras de internacionalización a lo
              largo y ancho de la UNAM.
            </p>
            <p>
              La jornada contará con la participación del iniciador de la
              perspectiva COIL, el Dr. Jon Rubin, fundador del portal COIL
              CONNECT para el intercambio virtual, que tanto está haciendo para
              enlazar docentes y estudiantes de universidades en todo el
              planeta. Habrá también un intercambio de experiencias COIL
              exitosas en la UNAM, con muestras de los conocimientos generados
              por medio de los cursos COIL, y talleres simultáneos de inducción
              a la metodología, de buenas prácticas para su desarrollo y de
              solución de conflictos y problemas en entornos COIL.
            </p>
            <p>
              La jornada se llevará a cabo el próximo jueves 19 de octubre de
              2023, de 9:30 a 14:00 horas, en el Centro de Formación y
              Profesionalización Docente de la UNAM (CFOP), ubicado en CU, cerca
              del Estadio Olímpico Universitario. El cupo para el evento estará
              limitado a 80 personas, por lo que invitamos al profesorado a
              realizar un pre-registro en el sitio en internet del programa
              <a href="https://coil.unam.mx">COIL UNAM</a> si desean asistir
              presencialmente. Las actividades se transmitirán por el canal de
              YouTube de la Comisión COIL y por el Facebook Live de la DGECI.
              ¡No te lo pierdas!
            </p>
          </article>
          <article>
            <h2>Reseña</h2>
            <h3>COIL dentro de la internacionalización académica</h3>
            <p class="text-right">Reseña por Adriana Núñez Puertos</p>
            <p>
              Beelen, Jos; Doscher Stephanie, 2022 ‘’Situating COIL virtual
              exchange within concepts of internationalization’’. Rubin, Jon,
              Guth Sara, ‘’The Guide to COIL virtual exchange’’. Virginia,
              Routledge.
            </p>
            <p>
              El artículo: Situando a COIL, intercambio virtual dentro de los
              conceptos de internacionalización; es el tercer capítulo del
              libro, ‘’The guide to COIL virtual exchange’’ publicado en
              Virginia, Estados Unidos de América. A continuación, reseñamos sus
              principales aportaciones.
            </p>
            <p>
              La internacionalización es relevante y necesaria para las
              instituciones de educación superior porque es el medio por el cual
              se van a alcanzar los objetivos de cada entidad educativa.
            </p>
            <p>
              En el contexto de la globalización, el proceso de
              internacionalización puede ser diferente según el contexto
              institucional, geográfico, político y económico, entre otros. Es
              tarea de las instituciones académicas adaptarse y generar para sus
              estudiantes vías de conocimiento y de desarrollo de perspectivas;
              comunicación y competencias interculturales por medio de la
              internacionalización.
            </p>
            <p>
              Diversos motivos orientan el trabajo de internacionalización en
              las instituciones, como cooperación, prestigio, capacidad de
              aumentar las habilidades en la educación para la empleabilidad, de
              aprovechar las herramientas tecnológicas desde casa y promover el
              trabajo en equipo, el aprendizaje y la enseñanza, así como la
              diversidad que surge durante los intercambios.
            </p>
            <p>
              Existen situaciones externas y diferencias entre instituciones en
              el contexto global, que reducen las posibilidades de realizar
              intercambios estudiantiles. Es por esto que la
              internacionalización de los estudiantes desde casa se presenta
              como una alternativa, pues proporciona herramientas necesarias
              para cumplir con objetivos de internacionalización de las
              instituciones, aumenta la igualdad de oportunidades, y fortalece
              la diversificación y la inclusión.
            </p>
            <p>
              La internacionalización en casa es una oportunidad para diseñar
              conocimiento, y aprendizaje global, para desarrollar habilidades y
              capacidades de comunicación, comparar perspectivas, colaborar
              entre estudiantes, y mucho más. Para llegar a ello, se deben
              establecer objetivos de aprendizaje de los planes de estudios por
              un periodo de tiempo limitado.
            </p>
            <p>
              Dentro del proceso de internacionalización académica sin realizar
              movilidad , la metodología de aprendizaje colaborativo
              internacional en línea (COIL por sus siglas en inglés) es un
              instrumento que logra objetivos, promueve el intercambio de
              experiencias e ideas, y permite la planeación y el trabajo con
              pares extranjeros.
            </p>
            <p>
              Las ventajas de la metodología COIL están en la
              internacionalización a distancia de la educación y de la
              enseñanza, la planeación y el diseño curricular en conjunto. El
              resultado del trabajo entre pares es la colaboración en la
              resolución de tareas, actividades o problemas complejos, donde las
              diversas perspectivas estructurales y cognitivas juegan un papel
              importante.
            </p>
          </article>
          <article>
            <h2>Eventos</h2>
            <h3><em>Webinario</em></h3>
            <p>
              La importancia de las encuestas y la recopilación de datos sobre
              la evolución del área del intercambio virtual (<em
                >The Importance of Surveys and Data Gathering Across the
                Evolving Virtual Exchange Field</em
              >).
            </p>
            <p>
              Miércoles 6 de septiembre de 2023, 11h00 am EDT (9h00 tiempo de
              CDMX)
            </p>
            <p>
              En este webinario, la plataforma COIL Connect y la Iniciativa
              Stevens explorarán las encuestas y otros medios de recopilación de
              información que cada organización aplica para sistematizar sus
              estrategias de intercambio virtual. Se busca demostrar el impacto
              de estos estudios en el desempeño de gestores COIL y personas que
              los llevan a cabo.
            </p>
            <p>
              Los ponentes brindarán sus definiciones de lo que son los COIL y
              el intercambio virtual, y analizarán el papel de estas prácticas
              en el cambiante paisaje de la internacionalización académica.
            </p>
            <p>
              Cada equipo compartirá las metodologías y medios de consulta que
              utilizan. La Encuesta Stevens está en marcha y puede responderse
              <a
                href="https://www.stevensinitiative.org/survey-
of-the-virtual-exchange-field"
                target="_blank"
                >aquí</a
              >.
            </p>
            <p>
              Las encuestas de COIL Connect, integradas en el proceso de
              registro de nuevos miembros, se encuentran en proceso de revisión
              y serán relanzadas en octubre. Los asistentes al webinario podrán
              comentarlas y aportar sus opiniones y perspectivas.
            </p>
            <p>
              El
              <a
                href="https://us02web.zoom.us/meeting/register/tZYoc-muqz8sHNZQqDA8O6fqZhRLrJnFFypx"
                target="_blank"
                >enlace para el registro a este webinario</a
              >.
            </p>
            <p>
              La actividad contará con traducción simultánea en inglés y
              español.
            </p>
          </article>
        </section>
        <p class="mt-6 text-center">
          <router-link
            :to="{ name: 'previos' }"
            class="bg-c_darkmagenta hover:bg-c_lightmagenta p-2 rounded text-gray-50"
          >
            ver otros números
          </router-link>
        </p>
      </div>
    </article>
  </section>
</template>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "AgostoView",
  components: {
    HeaderCoil,
  },
  data() {
    return {
      title: "",
      urlEN: "https://coil.unam.mx/#/coilaborando/previos/agosto",
    };
  },
};
</script>
