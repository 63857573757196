<template>
  <section
    class="w-4/5 md:w-2/3 mx-auto bg-white text-c_darkblue shadow-xl rounded-sm"
  >
    <header-coil :title="title" />
    <registration-form />
  </section>
</template>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";
import RegistrationForm from "@/components/RegistrationForm";

export default {
  name: "ComissionView",
  components: {
    HeaderCoil,
    RegistrationForm,
  },
  data() {
    return {
      title: "Registro de comisión y consejeros COIL",
    };
  },
};
</script>
