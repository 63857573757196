<template>
  <form action="" @submit.prevent="onSubmit" id="comissions" class="p-4">
    <div class="grid gap-2 md:grid-cols-2">
      <div class="input-form">
        <label for="name"><span>*</span> Nombre</label>
        <input type="text" v-model="name" placeholder="Mario González" />
      </div>
      <div class="input-form">
        <div>
          <label for="email"><span>*</span> Correo electrónico</label>
          <input type="email" v-model="email" placeholder="micorreo@mail.com" />
        </div>
        <!--<div class="under-input">
          <p>
            <input type="checkbox" v-model="share_email" checked />
            Quiero recibir correos electrónicos con noticias
          </p>
        </div>-->
        <div class="under-input md:col-span-2">
          <p class="ml-4 text-xs">
            Tu correo electrónico no se compartirá, lo usaremos en caso de que
            necesitemos comunicarnos contigo.
          </p>
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="input-form">
        <label for="social_accounts">Tus redes sociales</label>
        <div class="under-input">
          <p class="text-xs">
            Sigue este patrón: <em>"twitter: @some, instagram: @some"</em>
          </p>
        </div>
        <input
          type="text"
          v-model="social_accounts"
          placeholder="twitter: @some, instagram: @some"
        />
      </div>
    </div>
    <div class="grid md:grid-cols-2">
      <div class="input-form">
        <label for="project_url">Sitio web, enlace, etc.</label>
        <input
          type="url"
          v-model="project_url"
          placeholder="https://miproyecto.com"
          class="!mt-6"
        />
      </div>
      <div class="input-form">
        <label for="">Fotografía o avatar</label>
        <div class="under-input">
          <p class="text-xs">
            Formatos soportados: jpg, png, svg, gif. Peso máximo: 2MB
          </p>
        </div>
        <input type="file" @change="previewFiles" />
      </div>
    </div>
    <div class="grid">
      <div class="input-form">
        <label for="bio"><span>*</span> Biografía</label>
        <div class="under-input">
          <p class="text-xs">Máximo 500 carácteres</p>
        </div>
        <textarea v-model="bio" cols="30" rows="3"></textarea>
      </div>
    </div>
    <div class="grid">
      <div class="input-form">
        <label for="events">Cursos COIL de los que formas parte</label>
        <select v-model="events">
          <option value=""></option>
        </select>
      </div>
    </div>
    <div class="my-4 text-right">
      <button type="reset" class="bg-c_lightblue p-2 m-1 rounded">
        Borrar
      </button>
      <button type="submit" class="bg-c_gold p-2 m-1 rounded">
        Quiero participar
      </button>
    </div>
  </form>
</template>

<script>
import Swal from "sweetalert2";
import api from "@/api";

export default {
  name: "RegistrationForm",
  data() {
    return {
      name: "",
      email: "",
      share_email: "",
      social_accounts: "",
      project_url: "",
      bio: "",
      events: "",
      photo: File,
    };
  },
  methods: {
    validateForm(bioData) {
      let message = "";
      if (!bioData.name) {
        message += "Falta <strong>Tu nombre</strong><br />";
      }
      if (!bioData.email) {
        message += "Falta <strong>Tu correo electrónico</strong><br />";
      }
      if (!bioData.bio) {
        message +=
          "Cuéntanos un poco de ti, comparte tu <strong>Biografía</strong><br />";
      }
      return message;
    },
    onSubmit() {
      let bioData = {
        name: this.name,
        email: this.email,
        share_email: this.share_email,
        social_accounts: this.social_accounts,
        project_url: this.project_url,
        bio: this.bio,
        events: this.events,
      };
      const validate = this.validateForm(bioData);

      if (this.photo.size === undefined) {
        this.photo = false;
      }
      if (!validate) {
        api.postBio(bioData, this.photo).then((res) => {
          if (res.id) {
            this.$router.push("/comunidad");
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title:
                "Tu perfil se ha creado correctamente, en hora buena, ya formas parte de la <strong>Semana HD</strong>",
            });
          } else {
            Swal.fire({
              title: "Error",
              html: "Lo sentimos.<br />Ocurrió un error al crear tu perfil.<br />Intenta de nuevo más tarde",
              icon: "error",
              confirmButtonText: "Continuar",
            });
          }
        });
      } else {
        Swal.fire({
          title: "¡Espera!",
          html: validate,
          icon: "warning",
          confirmButtonText: "Continuar",
        });
      }
    },
    previewFiles(event) {
      console.log(event.target.files[0]);
      this.photo = event.target.files[0];
      if (this.photo.size / 1000000 > 2) {
        Swal.fire({
          title: "¡Espera!",
          html: "El peso máximo de la imagen es de <strong>2MB</strong>",
          icon: "error",
          confirmButtonText: "Entendido",
          toast: true,
        });
      }
    },
  },
};
</script>
