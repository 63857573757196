<template>
  <section>
    <header-coil :title="title" :urlEN="urlEN" class="text-c_lightmagenta" />
    <div class="md:grid md:grid-cols-2">
      <div v-for="video in videos" :key="video.src" class="p-6">
        <div>
          <iframe
            class="w-full aspect-video"
            :src="'https://www.youtube.com/embed/' + video.src"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <p class="my-2 text-c_darkmagenta text-xs">{{ video.title }}</p>
          <p
            class="text-right text-xs pt-1 text-gray-500 border-t border-gray-300"
          >
            {{ video.date }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "TestimonialsView",
  components: {
    HeaderCoil,
  },
  data() {
    return {
      videos: [
        {
          src: "j-9EtCXw-bo",
          title: "Consejería en Línea: una experiencia COIL",
          date: "21 de junio 2024",
        },
        {
          src: "um5wGAxTjBU",
          title: "Análisis de la Mortalidad en Ecuador mediante Clustering",
          date: "04 de junio de 2024",
        },
        {
          src: "Ss7vUSGmshg",
          title: "Farmacontaminación en Chile y en México",
          date: "11 de agosto de 2023",
        },
        {
          src: "JNya-Csyjxk",
          title: "COIL con la Universidad Luca de Artes de Bélgica",
          date: "8 de agosto de 2023",
        },
        {
          src: "MM4rxqRs9-8",
          title:
            "Ojos que no ven ¿corazón que no siente? Farmacontaminación en Chile y en México, una experiencia COIL",
          date: "10 de julio de 2023",
        },
        {
          src: "gudFYZLisvg",
          title:
            "Biología, tecnología y sociedad: un curso colaborativo intercultural",
          date: "19 de junio de 2023",
        },
      ],
      title: "Testimonios",
      urlEN: "https://coil.unam.mx/en/#/testimonials",
    };
  },
};
</script>
