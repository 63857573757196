<template>
  <div id="app" class="">
    <burger-menu isOpen:="true" />
    <div id="page-wrap grid content-center min-h-screen">
      <router-view />
    </div>
  </div>
</template>

<script>
import BurgerMenu from "@/components/BurgerMenu.vue";

export default {
  name: "App",
  components: {
    BurgerMenu,
  },
  mounted() {
    /*const burgermenu = document.querySelector(".bm-burger-button");
    burgermenu.click();*/
  },
};
</script>

<style>
header {
  z-index: 10;
}
p {
  line-height: 1.8rem;
}
#lang {
  display: inline-block;
  background: #ffffff !important;
  color: #c758b6;
  padding: 0.6rem;
  position: absolute;
  top: 20px;
  right: 100px;
}
</style>
